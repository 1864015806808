import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import {Link} from "react-router-dom"
import HomeGallery from '../imageGallery/HomeGallery'

const EditorChoice = () => {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 500);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (

    <>
    <Container className="editorchoice-cont">
      <div className='editorchoice'>
         <h1 className=''>The one distinctively best</h1>
         <p className='editorchoice-para'>In Pixooter we believe that perspective of an image should be distilled. 
         {/* Editor makes sure to give you the only best. */}
         </p>
         <Link to="/home">
           <button className="editorchoice-btn">Editors' Choice</button>
         </Link>
      </div>
      <div className='mt-4 editorchoice-gallery'>
        {isDesktop ? (
          <HomeGallery type={'home'} height={200} width={250} className="ecGallery"/>
        ) : (
          <HomeGallery type={'home'} height={300} width="100%" className="ecGallery"/>
        )}
      </div>
    </Container>
    </>
  
  )
}

export default EditorChoice