import React from 'react'
import { GiCheckMark } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import "./payment.css"
const PaymentSuccess = () => {
  return (
    <div className="payment-success">
      <div className="payment-success-card">
        <GiCheckMark className="success-logo"/>
        <p>Payment Successful!!</p>
        <span>Congratulation your payment is received successfully.</span>
        <Link to="/" className="btn btn-primary payment-btn">Back to Home</Link>
      </div>
    </div>
  )
}

export default PaymentSuccess