import React, { useEffect } from "react";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
import useStyles from "../../authentication/authStyles";
import * as yup from "yup";
import axios from 'axios'
import InputGroup from "react-bootstrap/InputGroup";
import { Formik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../authentication/signup/signup.css'
import Recaptcha from "react-recaptcha";


const schema = yup.object().shape({
    name: yup.string().required('Username is required').min(3, 'User must be above 3 characters'),
    message: yup.string().required('Message is required').min(10, 'Message must be above 10 characters'),
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    recaptcha: yup.string().required("ReCaptcha is required"),
});

const Contact = () => {
    const classes = useStyles();
   
    useEffect(()=>{
        const script = document.createElement("script");
        script.src =
            "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }, [])
    return (
        <Container className={classes.login_container}>
            <Row className="justify-content-center">
                <Col lg={5} md={7} className="py-5">
                    <ToastContainer className={classes.toast_container}/> 

                    {/* <BrandTitle /> */}
                    <Card className={classes.login_card}>
                        <h3 className={classes.login_card_header}>Contact Us</h3>
                        <Formik
                            validationSchema={schema}
                            onSubmit={ (values, { setSubmitting, resetForm }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    resetForm();
                                    console.log(values)
                                    axios.post("/api/contact", values)
                                    .then(res => {
                                        if(res.data.status === 200){
                                            toast(res.data.message)
                                        }else{
                                            toast(res.data.message)
                                        }                                                 
                                    })  

                                })
                                }
                            }
                            initialValues={{
                                name: "",
                                email: "",
                                message: "",
                                recaptcha: "",
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                                setFieldValue,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row >
                                        <Form.Group className="mb-3" controlId="validationFormikName">
                                            <InputGroup hasValidation>
                                                
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Full Name"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.name && !!touched.name
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className="login_feedback_errors">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationFormikEmail">
                                            <InputGroup hasValidation>
                                                
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Email"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.email && !!touched.email
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className="login_feedback_errors">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationFormikMessage">
                                            <InputGroup hasValidation>
                                                
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: '100px' }}
                                                    type="text"
                                                    placeholder="Enter your message"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="message"
                                                    value={values.message}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.message && !!touched.message
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className="login_feedback_errors">
                                                    {errors.message}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <div className="mb-3">
                                            <Recaptcha
                                                // sitekey not working in localhost
                                                // sitekey="6LeiokYiAAAAAGWuNneCBDkiWyISnDMu_Ayjztq1"

                                                // sitekey working in localhost and will not work in server
                                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                render="explicit"
                                                verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                                onloadCallback={() => { console.log("done loading!"); }}
                                            />
                                            {errors.recaptcha && touched.recaptcha && (
                                                <p className="login_feedback_errors mb-0">{errors.recaptcha}</p>
                                            )}
                                        </div>
                                        <Form.Group
                                            className="mb-1 signup-terms-div"
                                            controlId="formBasicCheckbox"
                                        >
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className="send-btn">
                                                Send Message
                                            </Button>
                                        </Form.Group>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;