import React,{useState,useEffect} from 'react'
import StackGrid from "react-stack-grid";
import HomeModal from './HomeModal';
import axios from "axios";
import useStyles from "./homeStyles";
import { useParams } from 'react-router-dom';
import "./homestyles.css"
import { FcLikePlaceholder } from "react-icons/fc";
import Spinner from 'react-bootstrap/Spinner';
import { Container } from 'react-bootstrap';


const HomeGallery = ({type, width, height, datatype, key1}) => {
    const [getPhotos, setGetPhotos] = useState([]);          // for multiple photos example in home
    const [path,setPath] = useState('');
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [show, setShow] = useState(false);
    const [curIndex, setCurIndex] =useState();
    const [photo,setPhoto] = useState({}); // for single photo example in modal
    const [loading, setLoading] = useState(true)

    const {id} = useParams();
    // console.log(key1, "key1")
    const getPhotosApi = async (type, datatype = "default",user_id, key1) => {
      console.log("type", type)
      // console.log(key1, "key1")

      var response;
        if(datatype === "profile") {
           response = await axios.get('/api/user-data-photos/' + user_id)
            setLoading(false)
        } else if(type === "home") {
           response = await axios.get('/api/get-images/' + type)
            setLoading(false)
        }
        // else if(key1 !== ""){
        //   response = await (
        //     await fetch('http://127.0.0.1:8000/api/search/' + key1)
        //   ).json();
        //   console.log(response.data, "response")
        // }
        else {
          response = await axios.get('/api/get-images/' + type)
          setLoading(false)

        }
        response = response.data;
        
        const newPhoto = response?.data;
        console.log(newPhoto)
        setGetPhotos(newPhoto)
        setPath(response?.path)
      }

      const getImageModal = (imageId) =>{
        axios.get(`/api/get-single-image/${imageId}`)
        .then((res) => {
            if(res.status === 200){
                setPhoto(res?.data?.data)
                setViewerIsOpen(true)
            }
        });
      }
      var user_id = id;

      if(localStorage.getItem('auth_data')){
        if(user_id === undefined){
            const user = JSON.parse(localStorage.getItem('auth_data'))
            user_id = user.id
        }
      }
      useEffect(() => {
        getPhotosApi(type, datatype,user_id, key1)
        // console.log(getPhotos);
      }, [user_id, key1, datatype, type])

      const openLightbox = (index) =>{
        setCurrentImage(index);
        setShow(true);
        setCurIndex(index)
       getImageModal(getPhotos[index].id)

    }
    console.log(currentImage)
    const childprops = {
        setCurrentImage:setCurrentImage,
        setViewerIsOpen:setViewerIsOpen,
        getImageModal:getImageModal,
        getPhotos:getPhotos,
        setCurIndex:setCurIndex,
        curIndex:curIndex,
        photo:photo,
        path:path,
        show:show,
        setShow:setShow
    }
    const classes = useStyles();


  return (
    loading ? 
    <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
        <Spinner animation="border" size="sm" /> 
    </Container> :
    <>
    <div className="my-4">
      <StackGrid 
      // columnWidth={250}
      columnWidth={width === "100%" ?  width <= 500 ? 350 : '100%' : 250} 
      monitorImagesLoaded={true} >
          {
              getPhotos?.map((item,ind)=>{
                  var thumb = item.thumbnail;
                  thumb = thumb.replace(/public/i,'storage');
                  return (
                      <div key={item.id} onClick={()=>openLightbox(ind)}
                      className="hover-body"
                      >
                        {
                          width === undefined ? 
                          <img className={classes.image_gallery} src={path+'/'+thumb} alt={item.caption}
                          //  onContextMenu={e => e.preventDefault()}
                          />
                          // : key1 !== "" ?
                          // <img className={classes.image_gallery} src={path+'/'+thumb} alt={item.caption} onContextMenu={e => e.preventDefault()}/>
                          : 
                          <img className={classes.image_gallery} style={{height:height+"px", width:width+"px"}}  src={path+'/'+thumb} alt={item.caption} 
                          // onContextMenu={e => e.preventDefault()}
                          />
                        }
                        <p className="m-0 hover-object d-flex justify-content-between">
                          <span className="ps-2">{item.name}</span>
                          <span className="pe-2 d-flex align-items-center"><FcLikePlaceholder className="me-1"/>{item.total_likes}</span>
                        </p>
                      </div>
                  )
              })
          }
      </StackGrid>
    </div>

    {viewerIsOpen ? (
    <HomeModal modald={childprops} />
    ) : null}

    </>
  )
}

export default HomeGallery