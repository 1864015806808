import { Container, Col, Row, Spinner } from "react-bootstrap";
import React, { useEffect, useState, useCallback } from "react";
import useStyles from "../UserStyles";
import SideBar from "../SideBar";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./uploadimage.css"
import { MdCloseFullscreen, MdOpenInFull } from "react-icons/md";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
// import '../../detail/detail.css'


const ShowImage = () => {
    const classes = useStyles();
    const [image, setImage] = useState({});
    const { image_id } = useParams();
    const [path, setPath] = useState("");
    const [loading , setLoading]= useState(true)
    const handle = useFullScreenHandle()
    const [isFullscreen, setFullscreen] = useState(false)
    const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

    const reportChange = useCallback((state) => {
        // console.log(state)
        if (state === true) {
        setFullscreen(true)
        } else if (state === false) {
        setFullscreen(false)
        }
    }, [])
    useEffect(() => {
        axios
            .get("/api/get-edit-image/" + image_id)
            .then((res) => {
                console.log(res.data.data);
                if (res.status === 200) {
                    setImage(res?.data?.data);
                    setPath(res.data.path);
                    setLoading(false)
                    
                    // setSelected(res?.data?.data?.tags?.split(","))
                }
            })
            .catch((err) => console.log(err));
    }, [image_id]);

    // let tags = [image.tags]
    // let arrayTags =JSON.parse(tags) 
    // console.log(tags)


    const updateMedia = () => {
        setDesktop(window.innerWidth > 768);
    };
    
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        
        <>
            <div className="container-xl">
                <Row>
                    {
                        (isDesktop) 
                        ?
                            <Col lg={3} className="mt-3">
                                <div className={classes.user_sidebar}>
                                    <SideBar />
                                </div>
                            </Col>
                        :
                            <Col lg={3} className="sidebar-resp">
                                <div className={classes.user_sidebar}>
                                    <SideBar />
                                </div>
                            </Col>
                    }
                    <Col lg={9}  className="my-4 content-resp">
                        <Row>
                            <Col lg={12} className="edit-image-header">
                                <h2 className="m-0">Preview Image</h2>
                                <Link className="btn previewimage-back-btn" to="/user/image-management">
                                    {" "}
                                    Back
                                </Link>
                            </Col>
                        </Row>
                        {
                        (loading) ?
                            <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
                                <Spinner animation="border" size="sm" /> 
                            </Container>:
                            <>
                                <Row>
                                    <div className="col-md-7">
                                        <div className="form-group">
                                            {/* <h6>
                                                {" "}
                                                <label className="fw-bold">
                                                    Image:
                                                </label>
                                            </h6> */}
                                            <div className="preview-imagedata-img">
                                            <button onClick={(e)=>{
                                                handle.enter() 
                                                setFullscreen(true)
                                             }} className="fullscreen-button"><MdOpenInFull className="fullscreen-icon"/></button>
                                                <img src={path + "/" +image?.thumbnail?.replace(/public/i,"storage")} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 preview-img-details" >
                                        <Col md={12}>
                                            <h6>
                                                {" "}
                                                <label className="fw-bold">
                                                    Category:
                                                </label>{" "}
                                                {image.category_name}
                                            </h6>
                                        </Col>
                                        <Col md={12}>
                                            <h6>
                                                {" "}
                                                <label className="fw-bold">
                                                    Price:
                                                </label>{" "}
                                                {image.price}
                                            </h6>
                                        </Col>
                                        <Col md={12}>
                                            <h6>
                                                {" "}
                                                <label className="fw-bold">
                                                    Caption:
                                                </label>{" "}
                                                {image.caption}
                                            </h6>
                                        </Col>
                                        <Col md={12}>
                                            <h6>
                                                {" "}
                                                <label className="fw-bold">
                                                    Description:
                                                </label>{" "}
                                                {image.description}
                                            </h6>
                                        </Col>
                                    </div>
                                </Row>
                                {/* <Row>
                                    <Col md={4}>
                                        <h6>
                                            {" "}
                                            <label className="fw-bold">
                                                Category:
                                            </label>{" "}
                                            {image.category_name}
                                        </h6>
                                    </Col>
                                    <Col md={4}>
                                        <h6>
                                            {" "}
                                            <label className="fw-bold">
                                                Price:
                                            </label>{" "}
                                            {image.price}
                                        </h6>
                                    </Col>
                                    <Col md={4}>
                                        <h6>
                                            {" "}
                                            <label className="fw-bold">
                                                Caption:
                                            </label>{" "}
                                            {image.caption}
                                        </h6>
                                    </Col>
                                    <Col md={6}>
                                        <h6>
                                            {" "}
                                            <label className="fw-bold">
                                                Tags:
                                            </label>{" "}
                                            {image.tags}
                                        </h6>
                                    </Col>
                                    <Col md={12}>
                                        <h6>
                                            {" "}
                                            <label className="fw-bold">
                                                Description:
                                            </label>{" "}
                                            {image.description}
                                        </h6>
                                    </Col>
                                
                                </Row> */}
                            </>
                        }
                    </Col>
                </Row>

                <FullScreen handle={handle} onChange={reportChange}
                    className={`${
                        isFullscreen
                        ? "d-flex flex-column align-items-center justify-content-center w-full h-screen"
                        : "d-none"
                    }`}>
                    <div className={classes.detail_fullscreen}>
                        <img 
                        src={path + "/" +image?.thumbnail?.replace(/public/i,"storage")} 
                        className={classes.fullscreen_image} alt="" />

                        <button  
                        onClick={() => {
                        handle.exit()
                        setFullscreen(false)
                        }}
                        className={classes.fullscreen_button}>
                            <MdCloseFullscreen className="fullscreen-icon"/>
                        </button>
                    </div>
                
                </FullScreen>
            </div>
        </>
    );
};

export default ShowImage;
