import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Navigate, Route, useLocation } from "react-router-dom";
import Navbar from "./components/navbar/NavbarMenu";
import Footer from "./components/footer/Footer";
import Detail from "./components/detail/Detail";
import Login from "./components/authentication/login/Login";
import ForgetPassword from "./components/authentication/login/ForgetPassword";
import ResetPassword from "./components/authentication/login/ResetPassword";
import Signup from "./components/authentication/signup/Signup";
import Dashboard from "./components/admin/Dashboard";
import AboutUs from "./components/footer/company/Aboutus";
import Privacy from "./components/footer/company/Privacy";
import Licensing from "./components/footer/company/Licensing";
import Terms from "./components/footer/company/Terms";
import Contact from "./components/footer/needHelp/Contact";
import Faq from "./components/footer/needHelp/Faq";
import Pricing from "./components/footer/needHelp/Pricing";
import SubmissionReq from "./components/footer/needHelp/SubmissionReq";
import UserHome from "./components/user/dashboard/Home";
import UserCart from "./components/user/cart/Cart";
import UserImageManagement from "./components/user/uploadImage/Image_management";
import UserPurchasedImage from "./components/user/purchasedImage/Purchased_image";
import UserSoldImage from "./components/user/soldImage/Sold_image";
import Cart from "./components/user/cart/Cart";
import AddImage from "./components/user/uploadImage/AddImage";
import EditImage from "./components/user/uploadImage/EditImage";
import ShowImage from "./components/user/uploadImage/ShowImage";
import axios from "axios";
import Admin from "./components/authentication/login/Admin";
import config from './config'
import HomeGallery from "./components/imageGallery/HomeGallery";
import LandingPage from "./components/home/LandingPage";
import UserProfile from "./components/user/userprofile/UserProfile";
import EditProfile from "./components/user/userprofile/EditProfile";
import Search from "./components/search/Search";
import ScrollToTop from "./ScrollToTop";
import NotFound from "./components/NotFound";
import Compitition from "./components/compitition/Compitition";
import { PaymentFailed, PaymentSuccess } from './components/payment'


axios.defaults.baseURL = config.BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.withCredentials = true; // for laravel token
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("auth_token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

function App() {

    return (
        <BrowserRouter>
            <div className="main-container">
                <Navbar />
                <ScrollToTop />
                <div className="sub-container">
                    <Routes>
                        <Route path='*' element={<NotFound />}/>
                        {/* <Route>{localStorage.getItem('auth_token')?<Navigate replace to="/user/home" /> : <Login/>}</Route>
                        <Route>{localStorage.getItem('auth_token')?<Navigate replace to="/user/home" /> : <Signup/>}</Route> */}
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/home" element={<HomeGallery />} />
                        <Route path="/detail" element={<Detail />} />
                        {/* <Route path="/login" element={localStorage.getItem('auth_token')?<Navigate replace to="/user/home" /> : <Login/>} /> */}
                        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>}/>
                        <Route path="/signup" element={<PublicRoute><Signup /></PublicRoute>} />
                        <Route path="/forgot-password"element={ <PublicRoute><ForgetPassword /></PublicRoute>}/>
                        <Route path="/reset-password/:token" element={<PublicRoute><ResetPassword /></PublicRoute>}/>
                        <Route path="/admin" element={<Admin />}/>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/aboutus" element={<AboutUs />} />
                        <Route path="/licensing" element={<Licensing />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/contactus" element={<Contact />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/search/:key1" element={<Search />} />
                        <Route path="/compitition" element={<Compitition />} />
                        <Route
                            path="/submission-requirements"
                            element={<SubmissionReq />}
                        />
                        <Route
                            path="/user/home"
                            element={
                                <ProtectedRoute>
                                    <UserHome />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/user/cart" 
                        element={
                            <ProtectedRoute>
                                <UserCart />
                            </ProtectedRoute>
                        } />
                        <Route
                            path="/user/sold-image"
                            element={
                                <ProtectedRoute>
                                    <UserSoldImage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/user/purchased-image"
                            element={
                                <ProtectedRoute>
                                    <UserPurchasedImage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/user/image-management"
                            element={
                                <ProtectedRoute>
                                    <UserImageManagement />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/user/image-management/add-image"
                            element={
                                <ProtectedRoute>
                                    <AddImage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/user/image-management/edit-image/:image_id"
                            element={
                                <ProtectedRoute>
                                   <EditImage />
                                </ProtectedRoute>
                           }
                        />
                        <Route
                            path="/user/image-management/show-image/:image_id"
                            element={
                                <ProtectedRoute>
                                    <ShowImage />
                                </ProtectedRoute>
                            }
                        />
                        {/* for logged in user */}
                        <Route
                            path="/user/profile"
                            element={
                                <ProtectedRoute>
                                    <UserProfile />
                                </ProtectedRoute>
                            }
                        />
                        {/* for other users */}
                        <Route
                            path="/user/profile/:id"     
                            element={
                                <ProtectedRoute>
                                    <UserProfile />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/user/edit-profile"
                            element={
                                <ProtectedRoute>
                                    <EditProfile />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/payment/success" element={<PaymentSuccess />} />
                        <Route path="/payment/cancel" element={<PaymentFailed />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

const PublicRoute = ({ children }) => {
    const user = localStorage.getItem("auth_token");
    return user ? <Navigate replace to="/" /> : children;
};

const ProtectedRoute = ({ children }) => {
    const location = useLocation()
    const user = localStorage.getItem("auth_token");
    return !user ? <Navigate replace to="/login" state={{from : location}} /> : children;
};
export default App;
