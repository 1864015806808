import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles(() => ({
    login_container: {
        // background: "rgb(255,255,255)",
        // background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,247,247,1) 100%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height: "calc(100vh - 200px)"
    },
    login_brand: {
        marginBottom: "0.9rem",
        textAlign: "center",
    },
    login_brand_a: {
        color: "#495057",
        textDecoration: "none",
        "&:hover": {
            color: "#495057",
        },
    },
    login_brand_h1: {
        fontSize: "2.1rem",
        fontWeight: 300,
    },
    login_card: {
        boxShadow: "0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)",
        padding: 20,
        textAlign: "center",
        // '& h3':{
        //     backgroundColor:"red"
        // }
    },
    login_card_header:{
        fontFamily:  "Arvo, serif",
        paddingBottom: 15,
        // textAlign: "left",
        // textTransform: "uppercase",
    },
    login_form_div: {
        position: "relative", 
    },
    login_form_input: {
        paddingRight:"2.5rem",
        '&:focus':{
            boxShadow: "none"
         },
    },
    login_form_logo: {
        position: "absolute",
        top: 10,
        right: 10,
        color: "#495057",
        fontSize: 20,
    },
    login_form_checkbox:{
        // fontWeight: 600,
        fontSize: 15,
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: 13,
          }
    },
    login_feedback_errors:{
        fontWeight: "600 !important",
        paddingLeft: 43,
        textAlign: "left",
        width: "100%",
        marginTop: "0.25rem",
        fontSize: ".875em",
        color: "#dc3545",
    },
    login_items:{
        fontSize:13,
        marginBottom: 6,
        width: "100%"
    },
    toast_container:{
        marginTop:"4rem !important"
    },
    auth_submit_btn:{
        padding:"0.35rem 1.35rem",
        width: "104px",
        backgroundColor:" #284782 !important",
        color: "#fff !important",
        border: "0!important",
        '&:hover':{
            boxShadow: "none"
         },
    },
    forget_pass_btn:{
        padding:"0.35rem 1.35rem",
        width: "100%",
        backgroundColor:" #284782 !important",
        color: "#fff !important",
        border: "0!important",
        '&:hover':{
            boxShadow: "none"
         },
    },
    toggle_password:{
        border:"none",
        background: "none",
        // padding:" 0.375rem 0.75rem",
    },
    login_items_div:{
        display: "flex",
        flexDirection: "column",
        textAlign: "initial",
    },
    redirecting:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        fontSize: 24,
    },
    redirecting_p:{
        fontFamily:  "Arvo, serif",
    },
}));

export default useStyles;
