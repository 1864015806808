import { Container, Row, Col } from 'react-bootstrap'
import React from 'react'
import useStyles from "./CompanyStyles";
import {GiSergeant} from "react-icons/gi"
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import '../footer.css'

const Privacy = () => {

  const classes = useStyles();

  return (
    <>
      <Container className='my-4'>
          <Row>
              <Col md={12}>
                <h2 className='section-header'><GiSergeant className={classes.title_icon}/>Privacy</h2>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">Information Collected by Pixooter</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                        We only collect personal information that is relevant to the purpose of our website. This information allows us to provide you with a customized and efficient experience. We collect the following types of information from our Pixooter users:
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">Information You Provide To Us</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                        1) We receive and store any information you enter on our website or provide to us in any other way. You can choose not to provide us with certain information, but then you may not be able to take advantage of many of our features.
                        </p>
                        <p className={classes.sub_para}>
                        2) Registration: In order for you to use Pixooter services you must complete a registration form. As part of this registration form, we require certain personal information. Our lawful basis for collecting personal information from you is to provide our services and to communicate with you with your consent when you register with us. Our collection of personal information from you is limited to information that we require in order to provide our services to you.
                        </p>

                        <p className={classes.sub_para}>
                        3) User Profile: To allow you to express yourself beyond just the information collected during registration, we enable you to provide additional information, such as your first and last names, email address, social media account links, a bio, and website URL. In addition, you may choose to include photos of yourself in your profile. As indicated below, in the section titled "Sharing Your Information", you can control how your information is displayed and used.
                        </p>

                        <p className={classes.sub_para}>
                        4) You have the right to edit, update, access, or transfer your personal information, or to delete your personal information if you are terminating your registration with Pixooter
                        </p>
                      </div>  
                    </div>
                  </div>
                </div>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">Automatic Information</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                        1) We receive and store certain types of information whenever you interact with us. Pixooter and its authorized agents automatically receive and record certain "traffic data" on their server logs from your browser and apps including your IP address, Pixooter cookie information, and the page you requested. Pixooter uses this traffic data to help diagnose problems with its servers, analyze trends, and administer the website. This does not involve collecting any of your personal information.
                        </p>
                        <p className={classes.sub_para}>
                        2) In addition to the "traffic data", Pixooter and its authorized agents may collect information about the device you're using to access Pixooter, its operating system, unique device identifier, device settings such as language, and crash data. Which data is specifically collected depends on the type of device and/or system running on that device. This data is again used to help us diagnose problems with the site and/or mobile apps and analyze trends. This does not involve collecting any of your personal information.
                        </p>

                        <p className={classes.sub_para}>
                        3) Pixooter may collect and use information regarding the geographic location of your devices. If the location data contains your personal information, then Pixooter will treat this location data in accordance with this Policy.
                        </p>

                        <p className={classes.sub_para}>
                        4) Pixooter may collect and, on any page, display the total counts that page has been viewed. This includes User Profile pages.
                        </p>
                        <p className={classes.sub_para}>
                        5) Many companies offer programs that help you to visit websites anonymously. While Pixooter will not be able to provide you with a personalized experience if we cannot recognize you, we want you to be aware that these programs are available.
                        </p>
                      </div>  
                    </div>
                  </div>
                </div>
                
              </Col>
          </Row>
      </Container>
      <ScrollUpButton style={{background: "transparent"}}/>
    </>

  )
}

export default Privacy