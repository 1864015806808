import React,{useState, useEffect} from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import useStyles from "./navStyles";
import { ImSearch } from "react-icons/im"; 
import axios from 'axios'
import { FiLogOut } from "react-icons/fi";
import { MdOutlineDashboard } from "react-icons/md";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiOutlineArrowUp } from "react-icons/ai";
import "./navstyles.css"
import Logo from "../../img/pixooterlogo.png"
import DefaultPhoto from "./../../img/img1.jpg";
import { TbAward } from "react-icons/tb";



const NavbarMenu = () => {
    const classes = useStyles();
    
    let user= JSON.parse(localStorage.getItem('auth_data'));
    const [userData, setUserData] = useState('')
    const [profilePhoto, setProfilePhoto] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const logOut=()=>{
        axios.post('/api/logout').then(res=>{
            if(res.data.status === 200){
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_data')
                navigate('/login',  {
                    state : {
                        from: location
                    }
                })
            }
        });
    }
    useEffect(()=>{
        if(localStorage.getItem('auth_token')){
            axios.get("/api/user-data/" + user?.id)
            .then((res) => {
                const resdata = res.data.data
                // console.log(resdata)
                setUserData(resdata)
                if(resdata.avatar !== null){
                    setProfilePhoto(res.data.path + "/storage/profile/" + resdata.avatar)
                }else{
                    setProfilePhoto(null)
                }
            })
            .catch((err) => console.log(err));
        }
    },[user?.id])
    
    const profile =()=>{
        navigate("/user/profile" )
    }
    const dashboard =()=>{
        navigate("/user/home" )
    }
    const cart =()=>{
        navigate("/cart" )
    }
    const compitition =()=>{
        navigate("/compitition" )
    }
    const login = ()=>{ 
        navigate("/login",  {
            state: {from : location},
        })
    }

    const uploadImage =()=>{
        navigate('/user/image-management/add-image')
    }

    const signup = ()=>{
        navigate("/signup",  {
            state : {
                from: location
            }
        })
    }
    // const [searchData, setSearchData]= useState([])

    const search=(key)=>{
        // console.log(key)
        axios.get('/api/search/' + key)
        .then((res)=>{
            // console.log(res.data)
            if(key !== undefined){
                // setSearchData(res.data)
                navigate("/search/" + key)
            }else{
                navigate("/")
            }
        }).catch((error)=>{
            console.log(error)
        })
    }
    const searchImage=()=>{
        // alert("key pressed")
    }
    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
          searchImage();
        }
    };

    // console.log(searchData)
    return (
        <>
            <Navbar className={classes.navbar_container}>
                <Container fluid className="navbar-cont">
                    <Link to="/" className={classes.navbar_brand}>
                        <img className="image-logo" src={Logo} alt=""/>
                    </Link>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                            {
                                localStorage.getItem('auth_token') ?
                                <>
                                    <Nav className="navbar-content">
                                        <span className="navbar-cart-og">
                                            <NavLink to="/compitition" className={classes.navbar_cart}>   
                                                <p className="mb-0 d-flex align-items-center"><TbAward className="cart-logo me-1"/>Pixooter's Contest</p>
                                            </NavLink>
                                        </span>
                                        <span className="mx-2 navbar-cart-og">
                                            <NavLink to="/cart" className={classes.navbar_cart}>   
                                                <p className="p-0 m-0 navbar-cart-title"><AiOutlineShoppingCart className="cart-logo me-1"/>Cart</p>
                                            </NavLink>
                                        </span>
                                        <span className="search-span">
                                            <input
                                                placeholder="Search"
                                                onChange={(e)=>{search(e.target.value)}}
                                                className={classes.navbar_input}
                                                aria-label="Search"
                                                onKeyDown={handleKeyPress}
                                            />
                                            <ImSearch onClick={searchImage}className={classes.navbar_icon} />
                                        </span>
                                        <span className="navbar-avatar-span">
                                            {
                                                profilePhoto !== null ?
                                                <img className="navbar-avatar" src={profilePhoto} alt="" /> :
                                                <img className="navbar-avatar" src={DefaultPhoto} alt="" />
                                            }
                                            <NavDropdown className="navbar-dropdown">
                                                <NavDropdown.Item className="navbar-userdatadropdown">
                                                    <div className="d-flex align-items-center py-2">
                                                        <div className="pe-3">
                                                            {
                                                                profilePhoto !== null ?
                                                                <img className="navbar-inneravatar" src={profilePhoto} alt="" /> :
                                                                <img className="navbar-inneravatar" src={DefaultPhoto} alt="" />
                                                            }
                                                        </div>
                                                        <div className="navbar-user-info">
                                                            <b>{userData.name}</b>
                                                            <p className="navbar-viewprofile m-0" onClick={profile}>View Profile</p>
                                                        </div>
                                                    </div>
                                                </NavDropdown.Item>
                                                <hr className="navbardropdown-hr" />
                                                <div className="navbar-cart-resp">
                                                    <NavDropdown.Item className="d-flex align-items-center me-1" onClick={cart}><AiOutlineShoppingCart className="my-1 me-2"/>Cart</NavDropdown.Item>
                                                    <hr className="navbardropdown-hr" />
                                                    <NavDropdown.Item className="d-flex align-items-center me-1" onClick={compitition}>
                                                        <TbAward className="my-1 me-2"/>Pixooter's Contest
                                                    </NavDropdown.Item>
                                                    <hr className="navbardropdown-hr" />
                                                </div>
                                                <NavDropdown.Item className="d-flex align-items-center me-1" onClick={dashboard}>
                                                    <MdOutlineDashboard className="me-2 my-1"/>Dashboard
                                                </NavDropdown.Item>
                                                <hr className="navbardropdown-hr" />
                                                <NavDropdown.Item className="d-flex align-items-center me-1" onClick={logOut}>
                                                    <FiLogOut className="me-2 my-1"/>Logout
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </span>
                                        <button className="btn navbar-upload-btn" onClick={uploadImage}><AiOutlineArrowUp className="navbar-upload-icon" />Upload</button>
                                    </Nav>
                                </>
                                :
                                <>
                                    <Nav className="d-flex justify-content-end w-100">
                                        <p onClick={login} className={classes.navbar_login_btn}>
                                                Login
                                        </p>
                                        <p onClick={signup} className={classes.navbar_login_btn}>
                                                Signup
                                        </p>
                                    </Nav>
                                </>
                            }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default NavbarMenu;
