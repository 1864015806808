import { makeStyles} from '@material-ui/core'

const useStyles = makeStyles( () => ({
    sub_para: {
        fontSize: 14,
        marginBottom: "0.5rem",
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "12px !important",
          }
    },
    title_icon:{
        transform: "rotate(90deg)",
        marginRight: 10
    },

    // about us
    aboutus_block:{
        margin: "1em 0",
        marginLeft: "2rem",
        ['@media (max-width:1025px)']: { // eslint-disable-line no-useless-computed-key
            marginLeft: "2rem",
          },
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            marginLeft: 20,
          }
    },
    aboutus_mint:{
        width: "3em",
        border: "2px solid #9b9b9b",
        margin: "0.4em 0"
    }
}));

export default useStyles