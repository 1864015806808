import React from 'react'
import Banner from './Banner'
import Contributer from './Contributer'
import EditorChoice from './EditorChoice'
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";

const LandingPage = () => {

  return (
    <>
        <Banner/>
        <EditorChoice/>
        <Contributer/>
        <ScrollUpButton style={{background: "transparent"}}/>
    </>
    
  )
}

export default LandingPage