import React from 'react'
import { AiOutlineHome } from "react-icons/ai"
import { BiImages } from "react-icons/bi"
import { MdOutlineAddAPhoto } from "react-icons/md"
import {NavLink} from "react-router-dom"
import useStyles from "./UserStyles";
import './userstyles.css'

const SideBar = () => {
  const classes = useStyles();

    return (
        <>
            <div className={classes.user_sidebar_component}>
                <nav className="sidebar-nav">
                    <ul className="nav nav-pills nav-sidebar" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <NavLink to="/user/home" className={classes.user_sidebar_element}>
                                <AiOutlineHome />
                                <p className='m-0 px-2'>Home</p>
                                <p className='m-0 px-2 resp'>Home</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user/image-management" className={classes.user_sidebar_element}>
                                <MdOutlineAddAPhoto/>
                                <p className='m-0 px-2'>Upload Images</p>
                                <p className='m-0 px-2 resp'>Upload</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user/purchased-image" className={classes.user_sidebar_element}>
                                <BiImages/>
                                <p className='m-0 px-2'>Purchased Images</p>
                                <p className='m-0 px-2 resp'>Purchased</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user/sold-image" className={classes.user_sidebar_element}>
                                <BiImages/>
                                <p className='m-0 px-2'>Sold Images</p>
                                <p className='m-0 px-2 resp'>Sold</p>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* <div className="sidebar-resp-component">
                <select ref={ref} className="form-select" onChange={event => handleChange(event.target.value)}>
                    <option id="select_user_home" value="/user/home">Home</option>
                    <option id="select_user_image-management" value="/user/image-management" >Upload Images</option>
                    <option id="select_user_purchased-image" value="/user/purchased-image">Purchased Images</option>
                    <option id="select_user_sold-image" value="/user/sold-image">Sold Images</option>
                </select>
            </div> */}
        </>
    )
}

export default SideBar