import React,{useState} from 'react'
import useStyles from "./homeStyles";
import { GrPrevious, GrNext } from "react-icons/gr";
import Detail from "../detail/Detail";
import {Modal} from "react-bootstrap";
import {MdOutlineDarkMode, MdOutlineLightMode} from 'react-icons/md'


const HomeModal = ({modald}) => {
    const classes = useStyles();
    console.log(modald.photo.caption)
      const closeLightbox = () => {
        modald.setCurrentImage(0);
        modald.setViewerIsOpen(false);
        modald.setShow(false);
    };

    const getImage = (ind)=>{
        modald.getImageModal(modald.getPhotos[ind].id)
        modald.setCurIndex(ind)
    }

    // const [theme , setTheme] = useLocalStorage('theme' ? "dark" : "light")
    const [theme, setTheme] = useState(false)

    const switchTheme=()=>{
        setTheme(!theme)
        if(theme === true){
            console.log("light")
        }else{
            console.log("dark")
        }
    }

    useState(()=>{
        console.log(modald.photo.id)
    },[modald.photo.id])

  return (
    <Modal
        dialogClassName={classes.home_img_modal}
        show={modald.show}
        onHide={closeLightbox}
        centered 
    >
        <div className={ theme ? 'dark-mode' : 'light-mode'}>
            <Modal.Header closeButton>
                <div className="d-flex justify-content-end w-100 detail-modal-header">
                    {/* <div>
                        <h4 className="m-0 ">{modald.photo.caption}</h4>
                    </div> */}
                    <button className="theme-icons-btn mx-2"
                    onClick={()=> switchTheme()}
                    >{theme ? <MdOutlineDarkMode className='theme-icons dark'/> : <MdOutlineLightMode className='theme-icons light'/>}</button>
                </div>
            </Modal.Header>
            <Modal.Body className={classes.home_img_modal_body}>
                
                <GrPrevious className="home-modal-navigateicons" onClick={(modald.curIndex!==0 ) ? ()=> getImage(modald.curIndex - 1) : ()=>getImage((modald.getPhotos.length-1)) }/>
                <Detail
                    currentIndex={modald.currentImage}
                    photo={modald.photo}
                    path={modald.path}
                />
                <GrNext 
                className="home-modal-navigateicons" 
                onClick={((modald.getPhotos.length-1) !== modald.curIndex) ? ()=> getImage(modald.curIndex + 1) : ()=>getImage(0) }/>
            </Modal.Body>
        </div>
    </Modal>
  )
}

export default HomeModal