import React, { useState, useContext ,useEffect    } from "react";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import useStyles from "./detailStyles";
import axios from 'axios'
// import { useParams } from "react-router-dom";
import { PhotoData } from "./Detail";
import './detail.css'


const DetailLike = ({chooseMessage}) => {
    const photo = useContext(PhotoData)
    const [likes, setLikes] = useState(0); // eslint-disable-next-line
    const [isClicked, setIsClicked] = useState(false); 
    const [likeLogo, setLikeLogo] = useState(false);

    useEffect(() => {
        setLikes(Number(photo?.total_likes))
        setLikeLogo((photo?.is_liked === 1)?true:false)
    }, [photo?.total_likes,photo?.is_liked])
    

    const handleClick = (id) => {
        axios.get(`/api/like/${id}`)
        .then((res) => {
            console.log(res)
            if(res.data.success === 1){
                if(likeLogo){
                    setLikes( likes - 1)
                    chooseMessage(likes - 1)
                }else{
                    setLikes( likes + 1) 
                    chooseMessage(likes + 1)
                }
            }
            setLikeLogo((prevState => !prevState))
        })
        .catch((err) => console.log(err)); 

    };


const classes = useStyles();

    return (
        <button
            className={`like-button ${isClicked && "liked"}` }
            onClick={()=>handleClick(photo.id)}

        >
            <span className="likes-logo">
                {likeLogo ? <FcLike className={classes.likes_logo}/> : <FcLikePlaceholder className={classes.likes_logo}/>}
            </span>
            <span className="likes-counter">
                {`${likes}`}
            </span>
        </button>
    );
};

export default DetailLike;
