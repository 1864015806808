import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import useStyles from "./CompanyStyles";
import {GiSergeant} from "react-icons/gi"
import '../footer.css'
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";


const Aboutus = () => {

  const classes = useStyles();

  return (
    <>
    <Container className='my-4'>
      <Row>
        <Col md={12}>
          <h2 className='section-header'><GiSergeant className={classes.title_icon}/>About Us</h2>
          <div className={classes.aboutus_block}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="dev-content">
                  <h5 className="title">Content</h5>
                  <div className={classes.aboutus_mint}></div>
                  <p>We believe that great content should speak for itself and we take pride in helping all photographers showcase their incredible work—it’s at the core of everything we do. dependability.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.aboutus_block}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="dev-content">
                  <h5 className="title">Growth</h5>
                  <div className={classes.aboutus_mint}></div>
                  <p>The creative journey doesn’t have an end; there’s always room for improvement. We’re committed to creating a platform that challenges you to keep learning, and step outside of your comfort zone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.aboutus_block}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="dev-content">
                  <h5 className="title">Recognition</h5>
                  <div className={classes.aboutus_mint}></div>
                  <p>You work hard as a photographer and we know the importance of being recognized for your work and skills. We’re constantly building ways to ensure our photographers get the recognition they deserve.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.aboutus_block}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="dev-content">
                  <h5 className="title">Grow as a photographer</h5>
                  <div className={classes.aboutus_mint}></div>
                  <p>Get immediate exposure with your first upload. Our Pulse algorithm surfaces new photographs and photographers, ensuring your photos are seen by the community so you receive valuable feedback on day one.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    <ScrollUpButton style={{background: "transparent"}}/>
    </>
  )
}

export default Aboutus