import React,{useContext, useState} from "react";
import useStyles from "./detailStyles";
import { PhotoData } from "./Detail";
import { Link } from "react-router-dom";
import axios from 'axios'
import DefaultPhoto from '../../img/img1.jpg'

const DetailPersonalInfo = () => {
   
    const classes = useStyles();
    const photo = useContext(PhotoData)
    const [profilePhoto, setProfilePhoto] = useState('')

    axios.get("/api/user-data/" + photo.user_id)
    .then((res) => {
        const resdata = res.data.data
        if(resdata.avatar !== null){
            setProfilePhoto(res.data.path + "/storage/profile/" + resdata.avatar)
        }else{
            setProfilePhoto(null)
        }
    })
    .catch((err) => console.log(err));

    return (
            <Link to={'/user/profile/' + photo.user_id} className="detail-profile-div">
                  {
                    profilePhoto ? 
                    <img className={classes.detail_profilephoto} src={profilePhoto} alt=""/> :
                    <img className={classes.detail_profilephoto} src={DefaultPhoto} alt=""/>
                  }
                    <div className={classes.detail_pname}>
                        <>
                            <p className="m-0 detail-caption">{photo?.caption}</p>
                            <p className="m-0 detail-name">by {photo?.name}</p>
                        </>
                    </div>
            </Link>
    );
};

export default DetailPersonalInfo;
