import React, {useState} from 'react'
import { Container } from 'react-bootstrap'
import './compitition.css'
import Banner from './Banner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Rules from './Rules';
import Prizes from './Prizes';
import Registration from "./Registration"

const Compitition = () => {
    const [currentTab, setCurrentTab] = useState("rules");
    const enrollNow = (eventKey) => {
        
        setCurrentTab("registration");
    }

  return (
    <>
    <Banner enrollNow={enrollNow}/>
    <Container>
        <div className="compitition my-3">
            <Tabs defaultActiveKey="rules" activeKey={currentTab} onSelect={(key) => setCurrentTab(key)} id="justify-tab-example" className="my-3" justify>
                <Tab eventKey="rules" title="Rules">
                    <Rules/>
                </Tab>
                <Tab eventKey="prizes" title="Prizes">
                    <Prizes/>
                </Tab>
                <Tab eventKey="registration" title="Registration" >
                    <Registration/>
                </Tab>       
            </Tabs>
        </div>
          
    </Container>
    </>

  )
}

export default Compitition