import { Col, Row, Button } from "react-bootstrap";
import React, {useState, useEffect} from "react";
import useStyles from "../UserStyles";
import SideBar from "../SideBar";
import { Link } from "react-router-dom";
import ImageList from "./ImageList";
import {BsPlusLg} from 'react-icons/bs';
import "../userstyles.css"
import './uploadimage.css'


const Image_management = () => {

    const classes = useStyles();
    const [isDesktop, setDesktop] = useState(window.innerWidth > 768);


    const updateMedia = () => {
        setDesktop(window.innerWidth > 768);
    };
    
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <div className="container-xl">
            <Row>
                {
                    (isDesktop) 
                    ?
                        <Col lg={3} className="mt-3">
                            <div className={classes.user_sidebar}>
                                <SideBar />
                            </div>
                        </Col>
                    :
                        <Col lg={3} className="sidebar-resp">
                            <div className={classes.user_sidebar}>
                                <SideBar />
                            </div>
                        </Col>
                }
                <Col lg={9} className="my-4 content-resp">
                    <div className="image-management-header mb-3">
                        <h2 className="m-0">Image Management</h2>
                        <Link to="/user/image-management/add-image">
                            <Button className="add-image-btn"><BsPlusLg className="me-2 add-image-icon"/>Add Image</Button>
                        </Link>
                    </div>
                    <ImageList />
                </Col>
            </Row>
        </div>
    );
};

export default Image_management;
