import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import useStyles from "./CompanyStyles";
import { GiSergeant } from "react-icons/gi"
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";

import '../footer.css'

const Licensing = () => {

  const classes = useStyles();


  return (
    <>
      <Container className='my-4'>
        <Row>
          <Col md={10}>
            <h2 className='section-header'><GiSergeant className={classes.title_icon} />Licensing</h2>
            <div className="section-data">
              <p className={classes.sub_para}>
                  In this section you will find information on the types of Licence rights available for all of ImagesBazaar products and each of the Licence agreements pertaining to those products and rights.
              </p>
              <div className={classes.aboutus_block}>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="dev-content">
                      <h5 className="title">Mash Non-Exclusive Right Licence Agreement</h5>
                      <div className={classes.aboutus_mint}></div>
                      <p className={classes.sub_para}>
                        Click here for details 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.aboutus_block}>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="dev-content">
                      <h5 className="title">Mash Comp Usage Agreement</h5>
                      <div className={classes.aboutus_mint}></div>
                      <p className={classes.sub_para}>
                        Click here for details 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.aboutus_block}>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="dev-content">
                      <h5 className="title">Mash Editorial Agreement</h5>
                      <div className={classes.aboutus_mint}></div>
                      <p className={classes.sub_para}>
                        Click here for details 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ScrollUpButton style={{background: "transparent"}}/>
    </>

  )
}

export default Licensing