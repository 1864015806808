import React,{useState,useEffect} from 'react'
import { Pagination } from 'react-bootstrap'
import "./uploadimage.css"
const Paginate = (props) => {
    const [pagination,setPagination] = useState([])
    let resdata = props.resdata;
    useEffect(() => {
    console.log(resdata);
    let items = [];
    let prev = resdata.prev_page_url;
    let next = resdata.next_page_url;
    let active = resdata.current_page;
    let lastpage = resdata.last_page;
    let path = resdata.path;
    items.push(<Pagination.Item key={0} disabled={active === 1} onClick={()=>props.onClickHandle(prev)}>Prev</Pagination.Item>)
    for (let i = 1; i <= lastpage; i++) {
        items.push(<Pagination.Item 
            key={i} 
            active={i === active} 
            onClick={()=>props.onClickHandle(path+"?page="+i)}
            >{i}</Pagination.Item>)
    }
    items.push(<Pagination.Item key={lastpage+1} disabled={active===lastpage}  onClick={()=>props.onClickHandle(next)}>Next</Pagination.Item>)
    setPagination(items);
    }, [resdata, props])
    
  return (
    <div>
        <Pagination>
            {pagination}
        </Pagination>
    </div>
  )
}

export default Paginate