import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import useStyles from "../company/CompanyStyles";
import "../footer.css"
import {GiSergeant} from "react-icons/gi"
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import '../footer.css'

const SubmissionReq = () => {

  const classes = useStyles();

  return (
    <>
      <Container className='my-4'>
          <Row>
              <Col md={12}>
                <h2 className='section-header'><GiSergeant className={classes.title_icon}/>Submission Requirements</h2>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">Technical requirements</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                        Our inspectors review each file to check for basic technical and quality requirements. Please review the requirements below before uploading and submitting your files for licensing. This will avoid unnecessary Changes required or Declined notices.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">File size and color mode</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                        We require JPEG files with a minimum 3MP file size.
                        </p>
                        <p className={classes.sub_para}>
                        Images must be saved in RGB color mode.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">Watermarks, trademarks and copyright</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                        High resolution images must not contain watermarks, signatures, or photo borders. These elements must be removed, along with any other creative/personalized text before submitting your photo. Clients cannot use high resolution photos that contain watermarks.
                        </p>
                        <p className={classes.sub_para}>
                        To avoid trademark infringement and to allow your images to be licensed for Creative (Commercial) use, prominent logos, branding, and company or product names must be removed. Not only is this a legal requirement but it also allows your images to be licensed to a wider variety of buyers. Branding includes registration numbers on boats, trains and vehicles.
                        </p>
                        <p className={classes.sub_para}>
                        If you have recognizable artwork in your photo we require a Property Release signed by the creator/copyright owner of the work. Examples of this are drawings, paintings and photographs that are visible within your image.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">Retouching</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                        A new French law was recently passed which obligates clients using Creative (Commercial) images in France to disclose whether the body shape of a model has been retouched to make them look thinner or larger. As of October 1st, 2017 we have updated our submission guidelines to require that Contributors submitting Creative (Commercial) images do not submit to us any images depicting models whose body shapes have been retouched to make them look thinner or larger. Similar to other licensing platforms in the industry, we will not accept images that have been manipulated in this way.
                        </p>
                        <p className={classes.sub_para}>
                          Some basic retouching is important before submitting your image for licensing i.e. removal of facial blemishes, stray hairs, chipped nail polish or dirt under fingernails, etc. Simple retouching can make your image more appealing to buyers.
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">Viewing your image</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                        Always view your image at 100% zoom. At 100%, quality can be assessed more easily as technical problems are more obvious and easy to detect.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
          </Row>
      </Container>
      <ScrollUpButton style={{background: "transparent"}}/>
    </>

  )
}

export default SubmissionReq