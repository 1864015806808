import {makeStyles} from '@material-ui/core'


const nav_btn = {
    // backgroundColor: "rgb(33,37,41)",
    '&:hover': {
        // background: "rgb(33,37,41)",
        backgroundColor: "#fff",
        color: "blue",
        // borderBottom: "1px solid #9b9b9b",
     },
    //  '&:focus':{
    //     // background:"none",
    //     border: "none",
    //     boxShadow: "none"
    //  },
    //  '&:active:focus':{
    //     borderBottom: "1px solid blue"
    // },
}



const useStyles = makeStyles( () => ({
    navbar_container:{
        position: "fixed",
        width: "100%",
        padding: "15px",
        zIndex: "1001",
        backgroundColor: "#fff",
        boxShadow: "0 0 15px rgb(0 0 0 / 10%)",
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            padding: 10
          }
    },
    navbar_brand: {
        color: "#000 !important"

    },
    navbar_login_btn: {
        border: "none",
        borderRadius: "30px",
        padding: '0.4rem 1.3rem',
        color: "#9b9b9b",
        fontWeight: 600,
        marginBottom: 0,
        cursor: "pointer",
        marginLeft: 10,
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: 14,
          }
    },

    navbar_input: {
        cursor: "default",
        paddingRight: 40,
        backgroundColor: "aliceblue",
        borderRadius: "2rem",
        fontSize: 14,
        padding: "8px 20px",
        border: "none",
     '&:focus':{
        outline: 0,
        border: 0,
        boxShadow: "none",
        backgroundColor: "aliceblue",
     },
     ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: 12,
        borderRadius: "10px",
        width: "100%",
      }
    },
    navbar_icon: {
        fontSize: 18,
        position: 'absolute',
        bottom: 10,
        right: 16,
        cursor: "pointer",
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: 15,
          }
    },
    navbarScroll:{
        display:"flex",
        flexDirection: "flex-end !important"
    },
    // navbar_logout_dropdown:{
    //     color: "black",
    // },
    navbar_cart:{
       display: "flex",
       alignItems: "center",
       padding: "5px 20px",
       color: "#9b9b9b",
       fontWeight:600,
    //    borderRadius: 5,
    //    marginLeft: 30,
    //    display: "block",
      ...nav_btn
      
    }
  }));

  export default useStyles