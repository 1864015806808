import { Button,Row,Col,Table, Spinner, Container} from "react-bootstrap";
// import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import {Link } from 'react-router-dom';
import {BiShow} from 'react-icons/bi'
import {TbEdit} from 'react-icons/tb'
import {RiDeleteBin2Line} from 'react-icons/ri'
import useStyles from "../UserStyles";
import Paginate from "./Paginate";
import {ImCheckmark,ImCross} from 'react-icons/im'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ImageList = () => {
    const classes = useStyles();
    const [imageData, setImageData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resData,setResData] =useState({})
    const [path,setPath] =useState("")
    const [serialno,setSerialNo] = useState(1);
    // const [modalShow, setModalShow] = useState(false);
    // const [selectedItem ,setSelectedItem] = useState({})

    const handleClick = (e,image_id) => {
        // const thisClicked = window.confirm('Do you want to delete the image?');
        // if(thisClicked){
            axios.delete('/api/delete-image/'+image_id)
            .then((res) => {
                getImageData();
                toast(res?.data?.message)
                // setModalShow(false)
            })
          .catch((err) => console.log(err));
        // } 
    };
 
    const onClickHandle = (pagelink)=>{
        getImageData(pagelink)
    }

    const getImageData = (pagelink = '/api/get-user-images') => {
        axios
            .get(pagelink)
            .then((res) => {
                if (res.status === 200) {
                    const resdata = res.data.data;
                    setResData(resdata);
                    setLoading(false);
                    setImageData(resdata.data);
                    setPath(res.data.path);
                    setSerialNo(resdata.from)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getImageData();
    }, []);

    let srno = serialno;

    return loading ? (
        <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
            <Spinner animation="border" size="sm" /> 
        </Container>
    ) : (
        <>
        <div className="table-scroller">
            <ToastContainer className={classes.toast_delete_container}/> 
            <Table striped bordered hover>

                <thead>
                    <tr >
                        <th className="text-center">No</th>
                        <th className="text-center">Image</th>
                        <th className="text-center">Category</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Is Exclusive</th>
                        <th className="text-center">Purchases</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (imageData?.length > 0) ?
                        
                        imageData?.map((image, ind) => {
                            return (
                                <tr key={ind++} className="align-middle">
                                    <td className="text-center">{srno++}</td>
                                    <td className="text-center">
                                        <img
                                            src={path +"/" +image.thumbnail.replace(/public/i,"storage")}
                                            width="50"
                                            height="50"
                                            alt="img"
                                        />
                                    </td>
                                    <td className="text-center">{image.category_name}</td>
                                    <td className="text-center">{image.price}</td>
                                    <td className="text-center">
                                        {image.is_exclusive === 0 ? <ImCross/> : <ImCheckmark/>}
                                    </td>
                                    <td className="text-center">{image.downloads}</td>
                                    <td className="text-center">
                                        {image.is_exclusive_sold !== 1 ? (
                                            image.status === 1 ? (
                                                <span className="text-secondary fw-bold">
                                                    Approved
                                                </span>
                                            ) : image.status === 2 ? (
                                                <span className="text-danger fw-bold">
                                                    Rejected
                                                </span>
                                            ) : image.status === 3 ? (
                                                <span className="text-danger fw-bold">
                                                    Deleted By Admin
                                                </span>
                                            ) : (
                                                <span className="text-warning fw-bold">
                                                    Waiting for approval
                                                </span>
                                            )
                                        ) : (
                                            <span className="text-success fw-bold">
                                                Sold
                                            </span>
                                        )}
                                    </td>
                                    <td className="text-center table-action-data">
                                        <Link to={"/user/image-management/show-image/"+image.id} >
                                            <Button className="m-1 view-image-btn" title="Preview Image"><BiShow className={classes.imagelist_icons}/></Button>
                                        </Link>
                                        <Link to={"/user/image-management/edit-image/"+image.id}>
                                            <Button className="btn btn-success m-1" title="Edit Image">
                                                <TbEdit className={classes.imagelist_icons}/>
                                            </Button>
                                        </Link>
                                        <Button className="btn btn-danger m-1" title="Delete Image" onClick={(e) => {handleClick(e ,image.id);}}>
                                            <RiDeleteBin2Line className={classes.imagelist_icons}/>
                                        </Button>
                                        
                                    </td>
                                </tr>
                                
                            );
                        })
                        :
                        <tr><td className="text-center" colSpan={8}>No products</td></tr>
                    }
                </tbody>
            </Table>
            

            
            {/* <Modal size="sm" className="delete-image-modal" centered  show={modalShow} >
                <Modal.Body>
                    <h4 className="text-center">Are You Sure?</h4>
                    <p className="text-center">This process cannot be undone</p>
                    <div className="deleteimage-btns">
                        <button className="btn btn-primary" onClick={() => setModalShow(false)}>Cancel</button>
                        <button className="btn btn-danger" onClick={(e) => {handleClick(e,image.id);}} >Delete</button>
                    </div>
                </Modal.Body>
            </Modal> */}

        </div>
            <Row>
                <Col md={12}>
                    <Paginate resdata={resData} onClickHandle={onClickHandle}/>
                </Col>
            </Row>

        </>
    );
};

export default ImageList;
