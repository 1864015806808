import { makeStyles } from "@material-ui/core";

const profileInfo ={
  '@media (max-width: 768px)': {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
}

const useStyles = makeStyles(() => ({

// detail_container: {
//   padding: "100px 0",
// },
detail_mainimage: {
  // marginBottom: 20,
  objectFit: "contain",
  maxHeight: "100%"
},
detail_download_btn: {
  display: "block",
  width: "100%",
},
detail_container_col: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor:"rgb(15, 15, 15)",
  height: "500px",
  padding: "0",
  position: "relative",
  '@media (max-width: 991px)': {
    height: "350px"
  },
  '@media (max-width: 767px)': {
    height: "300px"
  },
  '@media (max-width: 500px)': {
    height: "250px"
  },
},
detail_container_col_like: {
  width: 30,
  height: 30
},
detail_action_div:{
  '@media (max-width: 991px)': {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "1rem",
  }
},

//DetailPersonalInfo
detail_profilephoto:{
  borderRadius: "5px",
  width: 35,
  height: 35,
  objectFit: "cover"
},
detail_profilePhoto_col:{
    display: "flex",
    // justifyContent: "center",
    ['@media (max-width:991px)']: { // eslint-disable-line no-useless-computed-key
          ...profileInfo,

    }
},
detail_profileInfo:{
  // ...profileInfo,
  display: "flex",
  alignItems: "center",
  ['@media (max-width:991px)']: { // eslint-disable-line no-useless-computed-key
    ...profileInfo,
    paddingLeft: "20px",
  }
},
detail_pname:{
  marginLeft: 15
},
// detail_imgname:{
//   color: "#000"
// },

// DetailTags Css
detail_tags: {
  display: "flex",
  width: "100%",
  alignItems: "center",
  flexWrap: "wrap",
 
},
detail_tags_p:{
  marginBottom: 0,
  fontSize: "13px",
  fontWeight: 600,
  ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: "11px",
  }
},
detail_tags_badge:{
  display: "inline",
  marginLeft: 6,
  padding: "5px 10px",
  fontWeight: 100,
  fontSize: "12px",
  margin:"4px 0px",
  ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: "10px",
  }
}, 

// DetailImageInfo Css
detail_imageInfo: {
  fontSize: "13px",
  display: 'flex',
  justifyContent: "space-between",
  marginBottom: "0.2rem!important",
  ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: "11px",
  }
},
detail_description: {
  fontSize: "13px",
  marginBottom: "0.5rem",
  ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: "11px",
  }
},
detail_imageinfo_gears: {
  display: "inline-block",
  width: 100,
  fontWeight: 600
},
likes_logo:{
  width: 30,
  height: 30,
  transform: "translate(-4px, -3px)",
  ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
    width: 24,
    height: 24,
  }
},
detail_cartlogo:{
  fontSize: 20,
  marginRight: 15,
  marginBottom: 5,
},
// detail_fullscreen:{
//   position: "relative",
// },
fullscreen_image:{
  width: "90vw",
  height:"90vh",
  objectFit:"contain"
},
fullscreen_button:{
  position: "absolute",
  top: 0,
  right:0,
  backgroundColor: "#fff",
  border: "none",
  width: "40px",
  height: "40px",
  fontSize: 20

}

}));

export default useStyles;
