import React from "react";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import useStyles from "../authStyles";
import { CgMail } from "react-icons/cg";
// import { BsPerson } from "react-icons/bs";
import * as yup from "yup";
import InputGroup from "react-bootstrap/InputGroup";
import { Formik } from "formik";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const schema = yup.object().shape({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
});

const ForgetPassword = () => {
    const classes = useStyles();

    return (
        <>
            <Container className={classes.login_container}>
                <Row className="justify-content-center">
                    <Col md={4} className="py-5">
                        <ToastContainer className={classes.toast_container} />

                        <Card className={classes.login_card}>
                            <h3 className={classes.login_card_header}>Forget Password</h3>

                            <Formik
                                validationSchema={schema}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setTimeout(() => {
                                        setSubmitting(false);
                                        // resetForm();
                                        axios.get('/sanctum/csrf-cookie').then(response => {
                                            axios.post("/api/forgot-password", values)
                                                .then(res => {
                                                    const persons = res.data;
                                                    console.log(persons)
                                                    toast(persons.message)
                                                })
                                                .catch(function (error) {
                                                    // handle error
                                                    console.log(error)
                                                    // let res_error = error?.response?.data?.errors;
                                                    // toast(res_error.email[0]);
                                                })
                                        })
                                    })
                                }
                                }
                                initialValues={{
                                    email: "",
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row >
                                            <Form.Group className="mb-3" controlId="validationFormikEmail">
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">
                                                        <CgMail />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Email"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.email && !!touched.email
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-1"
                                                controlId="formBasicCheckbox"
                                            >

                                                <Button
                                                    type="submit"
                                                    className={classes.forget_pass_btn}
                                                >
                                                    Send Email Confirmation Link
                                                </Button>

                                            </Form.Group>
                                            <div className="text-start">
                                                <Link to="/login" className={classes.login_items}>Back to Login</Link>
                                            </div>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ForgetPassword;

