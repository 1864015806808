import React, {useState, useEffect} from 'react'
import { Container, Card } from 'react-bootstrap'
import Image from "../../img/img1.jpg"
import "./home.css"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner';

const Contributer = () => {

  const navigate = useNavigate()
  const [items, setItems] =useState([])
  const [path, setPath] = useState('')
  const [loading, setLoading] = useState(true)

  const usercard = ()=>{
    axios.get(`/api/user-data`).then(res=>{
      const resdata = res.data.data
      // console.log(res.data.data)
      // console.log(resdata[2].avatar)
      setItems(resdata)
      setPath(res.data.path + "/storage/profile/")
      setLoading(false)
    })
  }

  useEffect(() => {
   usercard()
  }, [])
  
  const handleClick=(id)=>{
    navigate("/user/profile/" + id)
  }
  return (
    <Container fluid className="p-0 ">
      <div className='contributer'>
        <h1>Top Contributors'</h1>
        <p className="contributer-para">Being consistent takes lot of efforts and we appreciate your work.</p>
        <div className='d-flex flex-wrap justify-content-center'>
          {

          loading ?
          <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
              <Spinner animation="border" size="sm"/> 
          </Container> :
          <>
            {
              items.map((elem, id)=>{
                return(
                  <Card key={id} className="contributer-card" onClick={()=>handleClick(elem.id)}>
                    <Card.Img  className="contributer-card-photo" src={elem.avatar ? path + elem.avatar : Image } />
                    <Card.Body >
                      <Card.Title className='contributer-card-name m-0'>{elem.name}</Card.Title>
                    </Card.Body>
                  </Card>
                )
              })
            }
          </>
          }
        </div>
      </div>
    </Container>
  )
}

export default Contributer