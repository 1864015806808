import React from "react";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
// import BrandTitle from "../BrandTitle";
import useStyles from "./../authStyles";
import { CgMail } from "react-icons/cg";
import { RiLock2Line } from "react-icons/ri";
import * as yup from "yup";
import axios from 'axios'
import { useNavigate, useParams, Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import { Formik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object().shape({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    password: yup.string().required('Password is required').matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
    password_confirmation: yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    let { token } = useParams();
    //    console.log(token)
    return (
        <Container className={classes.login_container}>
            <Row className="justify-content-center">
                <Col lg={5} md={7} className="py-5">
                    <ToastContainer className={classes.toast_container} />

                    {/* <BrandTitle /> */}
                    <Card className={classes.login_card}>
                        <h3 className={classes.login_card_header}>Reset Password</h3>
                        <Formik
                            validationSchema={schema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    // resetForm();
                                    console.log(values);
                                    axios.get('/sanctum/csrf-cookie').then(response => {
                                        axios.post("/api/reset-password", values)
                                            .then(res => {
                                                const persons = res.data;
                                                console.log(persons)
                                                
                                                navigate("/user/home", { replace: true }); 
                                                toast(persons.message)
                                            })
                                            .catch(function (error) {
                                                // handle error
                                                console.log(error)
                                                // let res_error = error?.response?.data?.errors;
                                                // toast(res_error.email[0]);
                                            })
                                    })
                                })
                            }
                            }
                            initialValues={{
                                email: "",
                                password: "",
                                password_confirmation: "",
                                token: token
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row >
                                        <Form.Group className="mb-3" controlId="validationFormikEmail">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                    <CgMail />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Email"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.email && !!touched.email
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationFormikPassword">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                    <RiLock2Line />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Password"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.password && !!touched.password
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationFormikConfirmPassword">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                    <RiLock2Line />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="password_confirmation"
                                                    value={
                                                        values.password_confirmation
                                                    }
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.password_confirmation && !!touched.password_confirmation
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                    {errors.password_confirmation}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                        >
                                            Confirm Changes
                                        </Button>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                        <div className="d-flex flex-column align-items-start">
                            <Link to="/login" className={classes.login_items}>Login</Link>
                            <Link to="/signup" className={classes.login_items}>Signup</Link>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPassword