/* eslint-disable jsx-a11y/alt-text */
import React,{createContext,useState, useCallback} from "react";
import { Col, Container, Row, Button} from "react-bootstrap";
import DetailImageInfo from "./DetailImageInfo";
import DetailPersonalInfo from "./DetailPersonalInfo";
import useStyles from "./detailStyles";
import DetailTags from "./DetailTags";
// import img1 from "../../img/img1.jpg
// import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import DetailLike from "./DetailLike";
import axios from 'axios';
import {AiOutlineShoppingCart} from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import './detail.css'
import { MdCloseFullscreen, MdOpenInFull } from "react-icons/md";
import {BiRupee} from 'react-icons/bi'
import { useNavigate } from "react-router-dom";

export const PhotoData = new createContext()
const Detail = ({photo,path}) => {
    const classes = useStyles();
    const navigate = useNavigate()
    const data = {photo}
    const [message, setMessage] = useState("")
    const chooseMessage = (message) => {
        setMessage(message);
    };
    console.log(message)
    const checkout= ()=>{
        navigate('/user/cart')
    }
    const submitAddtoCart = (e) => {
        e.preventDefault()
        axios.post('/api/add-to-cart/' + photo.id,{"viewtype":"api"}).then(res=>{
            const toastMsg = res?.data?.message
            console.log(toastMsg)
            const Msg = () => (
                <div>
                    <p className="mb-2">{toastMsg}</p>
                    <button className="toast-checkout-btn" onClick={checkout}>Checkout</button>
                </div>
              )
            toast(Msg())
        })
    }
    const userData = JSON.parse(localStorage.getItem('auth_data'));

    function formatDate(string){
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string)?.toLocaleDateString([],options);
    }

    var dateString = photo.updated_at
    const updatedDate = formatDate(dateString);
    const handle = useFullScreenHandle()
    const [isFullscreen, setFullscreen] = useState(false)

    const reportChange = useCallback((state) => {
        // console.log(state)
        if (state === true) {
        setFullscreen(true)
        } else if (state === false) {
        setFullscreen(false)
        }
    }, [],)
    console.log(photo)

    const small = photo.price
    const medium = Math.round(small * 1.1);
    const large = Math.round(small * 1.2);
    const xlarge = Math.round(small * 1.3);
    console.log(medium)
    return (
        <>
       <PhotoData.Provider value={photo}>
       <ToastContainer className={classes.toast_container}/> 
            <Container className={classes.detail_container}>
                <Row className="mx-2">
                    <Col lg={7} className={classes.detail_container_col}>
                        <button onClick={(e)=>{
                            handle.enter() 
                            setFullscreen(true)
                        }} 
                        className="fullscreen-button"><MdOpenInFull className="fullscreen-icon"/></button>
                        <img
                            className={classes.detail_mainimage}
                            style={{ width: "100%" }}
                            src={path +"/" +photo?.original?.replace(/public/i,"storage")}
                            onContextMenu={e => e.preventDefault()}
                        />
                        {/* <div className={classes.home_imgCaption_div}>
                             <FcLikePlaceholder onClick="like(this)"  className={classes.detail_container_col_like} />
                              {/* <a href="/cart"><BsCart3 className={classes.home_imgCaption_div_cart}/></a> */}
                        {/* </div>  */}
                    </Col>
                    <Col lg={1} className={classes.detail_action_div}>
                      {/* {
                        userData.id === data.photo.user_id ? "" : <Button onClick={submitAddtoCart} className="addToCart "><AiOutlineShoppingCart className={classes.detail_cartlogo}/></Button>
                      } */}
                        <DetailLike chooseMessage={chooseMessage}/>
                    </Col>
                    <Col lg={4} className="p-0">
                        <DetailPersonalInfo />
                        <hr className="detail-hr"/>
                        {
                            photo.user_id === userData.id ? "" :
                            <form className="">
                                <div className="d-flex" >

                                    <input type="radio" name="pricing" defaultChecked id="small"/>
                                    <label className="pricing-details" htmlFor="small">
                                        <span><BiRupee />{small}</span>
                                        <span>S</span>
                                        <span>1965 x 1310 / JPEG</span>
                                    </label>
                                </div>
                                <hr className="detail-hr"/>

                                <div className="d-flex">

                                    <input type="radio" name="pricing" id="medium" />
                                    <label className="pricing-details" htmlFor="medium">
                                        <span><BiRupee />{medium}</span>
                                        <span>M</span>
                                        <span>2948 x 1965 / JPEG</span>
                                    </label>
                                </div>
                                <hr className="detail-hr"/>

                                <div className="d-flex">

                                    <input type="radio" name="pricing" id="large"/>
                                    <label className="pricing-details" htmlFor="large">
                                        <span><BiRupee />{large}</span>
                                        <span>L</span>
                                        <span>3931 x 2620 / JPEG</span>
                                    </label>
                                </div>
                                <hr className="detail-hr"/>

                                <div className="d-flex">

                                    <input type="radio" name="pricing" id="extra-large"/>
                                    <label className="pricing-details" htmlFor="extra-large">
                                        <span><BiRupee />{xlarge}</span>
                                        <span>XL</span>
                                        <span>4914 x 3276 / JPEG</span>
                                    </label>
                                </div>
                                <hr className="detail-hr"/>

                                {/* <DetailLike chooseMessage={chooseMessage}/> */}
                                {
                                    userData.id === data.photo.user_id ? "" : <Button onClick={submitAddtoCart} className="addToCart "><AiOutlineShoppingCart className={classes.detail_cartlogo}/>Add To Cart</Button>
                                }
                            <hr className="detail-hr"/>
                            </form>
                        }
                        <p className={classes.detail_description}><b className="pe-2">Description:</b> {photo.description}</p>
                        <hr className="detail-hr"/>
                        
                        {
                            data.photo.exif_data === "0" || data.photo.exif_data === "null" ? 
                                <>
                                    <p className={classes.detail_imageInfo}>
                                        <span className={classes.detail_imageinfo_gears}>Uploaded</span> 
                                        <span>{updatedDate}</span>
                                    </p> 
                                    <hr className="detail-hr"/> 
                                </>
                                :
                                <>
                                    <DetailImageInfo/>
                                    <hr className="detail-hr"/>
                                </> 
                        }
                        <DetailTags/>
                        {/* <hr className="detail-hr"/>
                        <Button variant="success" className={classes.detail_download_btn}>Download</Button> */}
                    </Col>
                </Row>
            </Container>
        </PhotoData.Provider>

    {/* for full screen modal */}
    
        <FullScreen handle={handle} onChange={reportChange}
        className={`${
            isFullscreen
              ? "d-flex flex-column align-items-center justify-content-center w-full h-screen"
              : "d-none"
          }`}
      >
        <div className={classes.detail_fullscreen}>
            <img 
            src={path +"/" +photo?.original?.replace(/public/i,"storage")} 
            className={classes.fullscreen_image} alt="" />

            <button  
            onClick={() => {
            handle.exit()
            setFullscreen(false)
            }}
            className={classes.fullscreen_button}>
                <MdCloseFullscreen className="fullscreen-icon"/>
            </button>
        </div>
       
        </FullScreen>
        </>
    );
};

export default Detail;
