import { makeStyles} from '@material-ui/core'

const useStyles = makeStyles( () => ({
// sidebar
    user_sidebar:{
        position:'relative',
    },
    user_sidebar_component:{
        // height: "100vh",
        overflowY: "hidden",
        // zIndex: 1038,
        // bottom: 0,
        // top: 80,
        float: "none",
        left: 0,
        position: "absolute",
        // backgroundColor: "#fff9f9",
        width: "100%",
        // background:" rgb(255,247,247)",
        // background: "linear-gradient(180deg, rgba(255,247,247,1) 0%, rgba(255,249,249,1) 100%)",
        padding: '0 0.5rem',
        ['@media (max-width:991px)']: { // eslint-disable-line no-useless-computed-key
            // display: "none",
            position: "relative",
            display: "flex",
            padding: 0,
          },
       
          
    },
    user_sidebar_element:{
       display: "flex",
       alignItems: "center",
       padding: 10,
       color: "#222222",
       borderRadius: "5px !important",
       margin: 3,
       "&:hover":{
        backgroundColor: "#fff",
        color: "#222222"
       },
       ['@media (max-width:991px)']: { // eslint-disable-line no-useless-computed-key
        padding: "5px 18px",
        border: "1px solid #9b9b9b",
      },
       ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
        padding: "5px 0px 5px 7px",
      }
    },

    //imagelist

    imagelist_icons:{
        fontSize: 22,
        color: "white",
        paddingBottom: 5,
    },

    //userProfile

    toast_container:{
        marginTop: "4rem"
    },


    fullscreen_image:{
        width: "90vw",
        height:"90vh",
        objectFit:"contain"
    },
    fullscreen_button:{
    position: "absolute",
    top: 0,
    right:0,
    backgroundColor: "#fff",
    border: "none",
    width: "40px",
    height: "40px",
    fontSize: 20
    
    }
}));

export default useStyles