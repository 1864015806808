import {Col, Row } from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import SideBar from '../SideBar';
import useStyles from "../UserStyles";
import SoldList from './SoldList';

const Sold_image = () => {
  const classes = useStyles();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);


    const updateMedia = () => {
        setDesktop(window.innerWidth > 768);
    };
    
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
  return (
    <>
      <div className="container-xl">
        <Row>
          {
              (isDesktop) 
              ?
                  <Col lg={3} className="mt-3">
                      <div className={classes.user_sidebar}>
                          <SideBar />
                      </div>
                  </Col>
              :
                  <Col lg={3} className="sidebar-resp">
                      <div className={classes.user_sidebar}>
                          <SideBar />
                      </div>
                  </Col>
          }
          <Col lg={9}  className="my-4 content-resp">
          <div className="sold-image-header mb-3">
            <h2 className='m-0'>Sold Image</h2>
          </div>
          <SoldList/>
          </Col>
        </Row>
      </div>
    </>
  )
  

}
export default Sold_image









