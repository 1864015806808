import React,{useState, useContext, useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import useStyles from "./detailStyles";
import { PhotoData } from "./Detail";

const DetailImageInfo = () => {

    const photo = useContext(PhotoData)
    
    let exifData = [photo.exif_data]
    let arrayExifData =JSON.parse(exifData)  

    const height = arrayExifData?.COMPUTED?.Height
    const width = arrayExifData?.COMPUTED?.Width
    const aperture = arrayExifData?.COMPUTED?.ApertureFNumber
    const brand = arrayExifData?.MakerNote
    const model = arrayExifData?.Model
    const exposure_time = arrayExifData?.ExposureTime
    const focal_length = arrayExifData?.FocalLengthIn35mmFilm
    const iso = arrayExifData?.ISOSpeedRatings
   
    function formatDate(string){
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string)?.toLocaleDateString([],options);
    }
    
    const date_taken = arrayExifData?.DateTime === undefined ?  "-" : arrayExifData?.DateTime
    let vdate = date_taken?.substring(0,10)?.replaceAll(":", "/")
    const ogdate = date_taken === "-" ? date_taken : formatDate(vdate)
    var dateString = photo.updated_at
    const updatedDate = formatDate(dateString);

    const classes = useStyles();
    let varDim  = height === undefined ? "-" : height + " X " + width

    const [imageInfo,setImageInfo] = useState({});

    useEffect(()=>{ 
        setImageInfo({
            Brand: brand === undefined ? "-" : brand,
            Model: model === undefined ? "-" : model,
            Focal_Length: `${(focal_length === undefined ? "-" : focal_length )} mm`,
            Exposure_Time: `${(exposure_time === undefined ? "-" : exposure_time)} sec`,
            Aperture: aperture === undefined ? "-" : aperture,
            ISO_Speed: iso === undefined ? "-" : iso,
            Original_Size: varDim,
            Date_Taken: ogdate,
            Uploaded: updatedDate,
        })
    },[brand, varDim, model, focal_length, exposure_time, aperture, iso, ogdate, updatedDate])

    Object.keys(imageInfo).forEach( key =>  {
        if(imageInfo[key] === '-'){
            delete imageInfo[key]
        }
    });


    return (    
        <Row>
            <Col>
            {
              Object.keys(imageInfo).map(function(key) {
                
                return <p className={classes.detail_imageInfo} key={key}>
                        <span className={classes.detail_imageinfo_gears}>{key.replace(/_/,' ')}</span> 
                        <span>{imageInfo[key]}</span>
                    </p>
            })}
            </Col>
        </Row>
    );
};

export default DetailImageInfo;
