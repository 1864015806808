import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import useStyles from "../company/CompanyStyles";
import "../footer.css"
import {GiSergeant} from "react-icons/gi"
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";


const Pricing = () => {
  const classes = useStyles();
  return (
    <>
      <Container className='my-4'>
          <Row>
              <Col md={12}>
                <h2 className='section-header'><GiSergeant className={classes.title_icon}/>Pricing</h2>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="dev-content">
                        <h5 className="title">All Membership Plans</h5>
                        <div className={classes.aboutus_mint}></div>
                        <p className={classes.sub_para}>
                          Pixooter is founded on the principle of helping people discover new photos and photographers. We know that you care about how your personal information is used and shared, and we take your privacy very seriously. By visiting the Pixooter website, registering with Pixooter, or using any Pixooter services, you are accepting and agree to comply with this policy. The column on the right provides a short explanation of the privacy policy and is not legally binding.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
          </Row>
      </Container>
      <ScrollUpButton style={{background: "transparent"}}/>
    </>

  )
}

export default Pricing