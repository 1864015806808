import React,{useState, useEffect} from "react";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
// import BrandTitle from "../BrandTitle";
import useStyles from "./../authStyles";
import { CgMail } from "react-icons/cg";
import { RiLock2Line } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import * as yup from "yup";
import axios from 'axios'
import InputGroup from "react-bootstrap/InputGroup";
import { Formik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link,useLocation,useNavigate} from 'react-router-dom'
import Recaptcha from "react-recaptcha";
import './signup.css'


const schema = yup.object().shape({
    name: yup.string().required('Username is required').min(3, 'User must be above 3 characters'),
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    password: yup.string().required('Password is required').matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
    password_confirmation: yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
    terms: yup.bool().required().oneOf([true], ' '),
    recaptcha: yup.string().required("ReCaptcha is required"),
});

const Signup = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    const location = useLocation();
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const googleClick =()=>{
        axios.get('/api/login/google').then(res =>{
            console.log(res)
        })
    }
    useEffect(()=>{
        const script = document.createElement("script");
        script.src =
            "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }, [])

    return (
        <Container className={classes.login_container}>
            <Row className="justify-content-center">
                <Col lg={5} md={7} className="py-5">
                    <ToastContainer className={classes.toast_container}/> 

                    {/* <BrandTitle /> */}
                    <Card className={classes.login_card}>
                        <h3 className={classes.login_card_header}>Sign Up</h3>
                        <Formik
                            validationSchema={schema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    // resetForm();
                                    axios.get('/sanctum/csrf-cookie').then(response => {
                                        axios.post("/api/register", values)
                                            .then(res => {
                                                const persons = res.data;
                                                console.log(persons)

                                                if(res.data.status === 200){
                                                    localStorage.setItem('auth_token', res.data.token);
                                                    localStorage.setItem('auth_data', JSON.stringify(res.data.userdata))
                                                    if(location.state?.from){
                                                        navigate(location.state.from);
                                                    }else{
                                                        navigate("/", { replace: true });
                                                    }
                                                    toast(res.data.validation_error.message)

                                                }else{
                                                    let res_error = res?.data?.validation_errors;
                                                    toast(res_error.email[0])
                                                }
                                            })
                                        })
                                    })
                                }
                            }
                            initialValues={{
                                name: "",
                                email: "",
                                password: "",
                                password_confirmation: "",
                                terms: false,
                                recaptcha: "",
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                                setFieldValue,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row >
                                        <div>
                                            <p>Signup through following social sites</p>
                                            <button className="btn mb-3 social-btn google" onClick={googleClick}><FcGoogle className="social-btn-icon" />Continue with Google</button>
                                            <button className="btn social-btn facebook"><FaFacebook className="social-btn-icon" />Continue with Facebook</button>
                                            <div className="d-flex my-3">
                                            <p className="line-border"></p>
                                            <p className="btn-seprator">Or</p>
                                            <p className="line-border"></p>
                                            </div>
                                        </div>

                                        <Form.Group className="mb-3" controlId="validationFormikName">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                    <BsPerson />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Username"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.name && !!touched.name
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationFormikEmail">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                    <CgMail />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Email"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.email && !!touched.email
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationFormikPassword">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                    <RiLock2Line />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type={passwordShown ? "text" : "password"}
                                                    placeholder="Password"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.password && !!touched.password
                                                    }
                                                />
                                                <InputGroup.Text id="inputGroupPrepend">
                                                    <button className={classes.toggle_password} type="button" onClick={togglePassword}>{passwordShown ? <AiOutlineEye/>:<AiOutlineEyeInvisible/>}</button>
                                                </InputGroup.Text>
                                                <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="validationFormikConfirmPassword">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend">
                                                    <RiLock2Line />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="password_confirmation"
                                                    value={
                                                        values.password_confirmation
                                                    }
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.password_confirmation && !!touched.password_confirmation
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                    {errors.password_confirmation}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                         <div className="recaptcha-div">
                                            <Recaptcha
                                                // sitekey not working in localhost
                                                // sitekey="6LeiokYiAAAAAGWuNneCBDkiWyISnDMu_Ayjztq1"

                                                // sitekey working in localhost and will not work in server
                                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                render="explicit"
                                                verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                                onloadCallback={() => { console.log("done loading!"); }}
                                                        
                                            />
                                            {errors.recaptcha && touched.recaptcha && (
                                                <p className="signup-feedback-errors mb-0">{errors.recaptcha}</p>
                                            )}
                                        </div>
                                        <Form.Group
                                            className="mb-1 signup-terms-div"
                                            controlId="formBasicCheckbox"
                                        >
                                                <Form.Group >
                                                    <Form.Check
                                                    required
                                                    className="text-initial"
                                                    name="terms"
                                                    label="Agree to terms and conditions"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.terms}
                                                    feedback={errors.terms}
                                                    feedbackType="invalid"
                                                    id="validationFormik0"
                                                    />
                                                </Form.Group>
                                            {/* </label> */}
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className={classes.auth_submit_btn}
                                            >
                                                Sign Up
                                            </Button>
                                            <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                    {errors.agree_terms}
                                            </Form.Control.Feedback>
                                            {/* <div className="signup-membership-resp">
                                                <Link to="/login" className={classes.login_items}>I already have a membership</Link>
                                            </div> */}
                                        </Form.Group>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                        <div className="signup-membership-og">
                            <Link to="/login" className={classes.login_items}>I already have a membership</Link>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Signup;
