import React,{useState, useEffect} from 'react'
import {Card, Col, Container, Form, Row, InputGroup, Button, Tabs, Tab, Spinner} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';


const schema = yup.object().shape({
    name: yup.string().required('Username is required').min(3, 'User must be above 3 characters'),
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    phone:yup.string().matches(/^[6-9]\d{9}$/, {message: "Please enter valid number.", excludeEmptyString: false}).nullable()
  });


const EditProfile = () => {

    // const [myImage,setMyImage] = useState('')
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    // const proImageRef = useRef();
    const navigate= useNavigate()
    // const onImageChange = (e) =>{
    //     setMyImage(e.target.value)
    //     // setPreImage(URL.createObjectURL(proImageRef.current.files[0]))
    // }


    const user = JSON.parse(localStorage.getItem('auth_data'))
    console.log(user.id)
    useEffect(() => {
        axios.get("/api/get-profile-data/" + user.id)
            .then((res) => {
                console.log(res.data);
                if(res.status === 200){
                    setData(res?.data?.data);
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err));
    }, [user.id]);
    console.log(data.name)

    const back=()=>{
        navigate('/user/profile')
    }
  return (
    <Container>
        <div className="d-flex aligm-items-center justify-content-center my-5">
            <Card className="editprofile-card p-3">
                <Row>
                    <Col>
                        <span onClick={back}><BiArrowBack/></span>
                        <h1 className="editprofile-title">Edit Profile</h1>
                    </Col>
                   {
                     loading ? 
                     <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
                         <Spinner animation="border" size="sm"/> 
                     </Container> : 
                    <Col md={12}>
                        <Formik
                            validationSchema={schema}
                            onSubmit={(
                                values,
                                { setSubmitting, resetForm }
                            ) => {
                                setTimeout(() => {
                                    const formData = new FormData();
                                    formData.append("name",values.name);
                                    formData.append("email",values.email);
                                    formData.append("phone",values.phone?values.phone:'');
                                    formData.append("description",values.description? values.description:'');
                                    formData.append("website", values.website?values.website:'');
                                    formData.append("facebook", values.facebook?values.facebook:'');
                                    formData.append("instagram", values.instagram?values.instagram:'');
                                    // formData.append("image",proImageRef.current.files[0]);
                                    formData.append('_method', 'PATCH');
                                    formData.append("viewtype", "api");
                                    setSubmitting(false);
                                    // resetForm();
                                    axios.get("/sanctum/csrf-cookie").then((response) => {
                                        axios.post("/api/update-profile-data/" + user.id,formData)
                                            .then((res) => {
                                                const persons = res.data;
                                                console.log(persons);
                                                if ( res.data.status === 200 ) {
                                                    navigate("/user/profile",{ replace: true });
                                                    console.log(persons)
                                                    // toast(res?.data?.validation_error?.message);
                                                }
                                            });
                                        });
                                });
                                console.log(values);
                            }}
                            
                            initialValues={{
                                name: data.name,
                                // image: '',
                                email: data.email,
                                phone: data.phone,
                                description: data.description,
                                website: data.website,
                                facebook: data.facebook,
                                instagram: data.instagram,

                            }}
                            
                            >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (

                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row>
                                        {/* <Col md={12}>
                                            <div className='d-flex justify-content-center align-items-center'>
                                            
                                                <img className="userprofile-userimage" src={ProfilePic} alt=""  />
                                                 
                                            
                                            </div>
                                        </Col> */}
                                        <Tabs
                                            defaultActiveKey="photos"
                                            id="justify-tab-example"
                                            className="mb-3 pe-0"
                                            justify
                                            >
                                            <Tab eventKey="photos" title="User Details">
                                                <Col md={12}>
                                                    <Form.Group controlId="validationFormikname" className="mb-3">
                                                        <Form.Label className="editprofile-label">Name</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                            type="text"
                                                            aria-describedby="inputGroupPrepend"
                                                            name="name"
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.name}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                            {errors.name}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group controlId="validationFormikEmail" className="mb-3">
                                                        <Form.Label className="editprofile-label">Email</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                            disabled
                                                            type="text"
                                                            aria-describedby="inputGroupPrepend"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.email}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group controlId="validationFormikPhone" className="mb-3">
                                                        <Form.Label className="editprofile-label">Phone</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                            type="number"
                                                            aria-describedby="inputGroupPrepend"
                                                            name="phone"
                                                            value={values.phone}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.phone}
                                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                            className="editprofile-phoneinput"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                            {errors.phone}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Form.Group as={Col} md="12" controlId="validationFormikDescription" className="mb-3">
                                                    <Form.Label className="editprofile-label">Description</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                        as="textarea"
                                                        type="text"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="description"
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Tab>
                                            <Tab eventKey="gallery" title="Social Links">
                                                <Col md={12}>
                                                    <Form.Group controlId="validationFormikWebsite" className="mb-3">
                                                        <Form.Label className="editprofile-label">Website</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control
                                                            type="url"
                                                            aria-describedby="inputGroupPrepend"
                                                            name="website"
                                                            value={values.website}
                                                            onChange={handleChange}/>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group controlId="validationFormikFacebook" className="mb-3">
                                                        <Form.Label className="editprofile-label">Facebook</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                            type="url"
                                                            aria-describedby="inputGroupPrepend"
                                                            name="facebook"
                                                            value={values.facebook}
                                                            onChange={handleChange}
                                                            />
                                                            
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group controlId="validationFormikInstagram" className="mb-3">
                                                        <Form.Label className="editprofile-label">Instagram</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                            type="url"
                                                            aria-describedby="inputGroupPrepend"
                                                            name="instagram"
                                                            value={values.instagram}
                                                            onChange={handleChange}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Tab>
                                        </Tabs>
                                    </Row>
                                <Button className="editprofile-save-btn" type="submit">Save Changes</Button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                   }
                </Row>
            </Card>
        </div>
    </Container>
  )
}

export default EditProfile