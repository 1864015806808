import { Col, Row, Form, Button } from 'react-bootstrap'
import React, { useRef, useState,useEffect } from 'react'
import useStyles from "../UserStyles";
import SideBar from '../SideBar';
import { Link, useNavigate } from "react-router-dom"
import { Formik } from 'formik';
import * as yup from "yup";
import InputGroup from "react-bootstrap/InputGroup";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
// import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import { TagsInput } from "react-tag-input-component";
import LinearProgress from '@mui/material/LinearProgress';
// import { useCallback } from 'react';
import "../userstyles.css"

const AddImage = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    const [categories,setCategories] = useState([])
    const [preImage,setPreImage] = useState('')
    const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

    // const [myImage,setMyImage] = useState('')

    const onImageChange = (e) =>{
        // setMyImage(e.target.value)
        console.log(proImageRef.current.files[0])
        console.log(URL.createObjectURL(proImageRef.current.files[0]))
        setPreImage(URL.createObjectURL(proImageRef.current.files[0]))
        // console.log(URL.createObjectURL(proImageRef.current.files[0]))
    }
    
    const schema = yup.object().shape({
        caption: yup.string().required('Caption is required').min(3, 'Caption must be at least 3 characters').max(200, 'Caption must be at most 200 characters'),
        // tags: yup.string().required('Tags is required'),
        category: yup.string().required('Category is required'),
        price: yup.number().required('Price is required').min(1, 'Price must be at least 1').max(99999, 'Price must be less than or equal to 99999'),    // need to validate as per currency 
        description: yup.string().required('Description is required'),
        image: yup.mixed().nullable().required('Image is required')
        .test("fileSize", "Too small!", (value) => {
            return(
                (value && proImageRef.current) ?
                ((proImageRef.current.files[0].size >= 60000)  ? true : false)   //60kb
                : true)
        })
        .test("fileSize", "Too big!", (value) => {
            return(
                value && proImageRef.current ?
                (proImageRef.current.files[0].size <= 15000000  ? true : false)   //6mb
                : true)
            }),
            

        // image: yup.mixed().test("fileSize", "The file size is smaller than minimum requirements2", (value) => { 
        //     const min_val = 399181; return value && value[0].size < min_val}),
            
        // is_exclusive: yup.bool().oneOf([true], ''),
    });

    const proImageRef = useRef();

    // const [state, setState] = useState([])
    const [selected, setSelected] = useState([]);
    const [loader,setLoader] = useState(false)
    
    useEffect(() => {
      axios.get('/api/get-categories')
      .then((res)=>{
        setCategories(res?.data?.data)
      })
      .catch(err=>console.log(err))
    }, [])
    

    const updateMedia = () => {
        setDesktop(window.innerWidth > 768);
      };
    
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const onHandleBlur = () =>{
        
    }
    // const onExistHandler =useCallback(()=>{
    //     return false
    //     alert(1)
    // }, [])

    return (

        <>
            <div className="container-xl">
                <Row>
                {
                        (isDesktop) 
                        ?
                            <Col lg={3} className="mt-3">
                                <div className={classes.user_sidebar}>
                                    <SideBar />
                                </div>
                            </Col>
                        :
                            <Col lg={3} className="sidebar-resp">
                                <div className={classes.user_sidebar}>
                                    <SideBar />
                                </div>
                            </Col>
                    }
                    <Col lg={9} className="my-4 content-resp">
                        <Row className="mb-3">
                            <Col lg={12} className="add-image-header">
                                <h2 className="m-0">Add New Image</h2>
                                <Link className="btn add-image-btn" to="/user/image-management"> Back</Link>
                            </Col>
                        </Row>
                        <ToastContainer className={classes.toast_container} />

                        <Formik
                            validationSchema={schema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                console.log("filesize is :")
                                console.log(proImageRef.current.files[0].size)
                                setTimeout(() => {
                                    const formData = new FormData();
                                    formData.append('category',values.category);
                                    formData.append('price',values.price);
                                    formData.append('caption',values.caption);
                                    let mytags = selected?.map((elem)=>{
                                        return JSON.stringify({"value":elem})
                                    })
                                    formData.append('tags',`[${mytags}]`);
                                    formData.append('is_exclusive',values.is_exclusive);
                                    formData.append('description',values.description);
                                    formData.append('image',proImageRef.current.files[0]);
                                    formData.append('viewtype','api')
                                    setSubmitting(false);
                                    setLoader(true)
                                    // resetForm();
                                    axios.get('/sanctum/csrf-cookie').then(response => {
                                        axios.post("/api/add-images", formData)
                                            .then(res => {
                                                const persons = res.data;
                                                console.log(persons)

                                                if (res.data.status === 200) {

                                                    navigate("/user/image-management", { replace: true });
                                                    toast(res?.data?.validation_error?.message)
                                                    setLoader(false)
                                                } else {
                                                    let res_error = res?.data?.validation_errors;
                                                    toast(res_error.email[0])
                                                }
                                            })
                                    })
                                })
                                console.log(values);

                            }
                            }
                            initialValues={{
                                caption: "",
                                // tags: "",
                                price: "",
                                category: "",
                                is_exclusive: false,
                                description: "",
                                image: "",
                            }}
                            // enableReinitialize={true}

                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,

                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row>
                                       
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="validationFormikCategory">
                                                <InputGroup hasValidation className="d-flex flex-column">
                                                    <Form.Label className="fw-bold" >Category:</Form.Label>
                                                    <Form.Select aria-label="Default select example" isInvalid={
                                                        !!errors.category && !!touched.category}
                                                        type="text"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="category"
                                                        value={values.category}
                                                        onChange={handleChange}
                                                        className="w-100">
                                                        <option value="" disabled defaultValue hidden>Select Category</option>
                                                        {
                                                            categories?.map((category, i)=>{
                                                                return(
                                                                    <option key={i} value={category.id}>{category.name}</option>
                                                                )
                                                            })
                                                            }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.category}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="validationFormikPrice">
                                                <InputGroup hasValidation className="d-flex flex-column">
                                                    <Form.Label className="fw-bold" >Price:</Form.Label>
                                                    <Form.Control aria-label="Default select example" isInvalid={
                                                        !!errors.caption && !!touched.caption}
                                                        type="number"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="price"
                                                        value={values.price}
                                                        onChange={handleChange}
                                                        className="w-100">
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.price}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="validationFormikCaption">
                                                <InputGroup hasValidation className="d-flex flex-column">
                                                    <Form.Label className="fw-bold" >Caption:</Form.Label>
                                                    <Form.Control aria-label="Default select example" isInvalid={
                                                        !!errors.caption && !!touched.caption}
                                                        type="text"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="caption"
                                                        value={values.caption}
                                                        onChange={handleChange}
                                                        className="w-100">
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.caption}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="validationFormikTags">
                                                <InputGroup hasValidation className="d-flex flex-column">
                                                    <Form.Label className="fw-bold" >Tags:  <span style={{fontSize:'12px'}}>(Press "enter" or "space" to create tag)</span> </Form.Label>

                                                    {/* <InputTags values={tags} onTags={(tag) => values(tag.values)} /> */}
                                                    {/* <InputTags values={selected} onTags={(value) => setSelected(value.values)} className="w-100" /> */}
                                                    {/* <TagsInput
                                                        value={values.tags}
                                                        isInvalid={!!errors.tags && !!touched.tags}
                                                        name="tags"
                                                        onChange={handleChange}
                                                        className="w-100"
                                                    /> */}
                                                    <TagsInput
                                                    className="is-invalid"
                                                        value={selected}
                                                        onChange={setSelected}
                                                        seprators={[" ","Enter"]}
                                                        onBlur={onHandleBlur}
                                                        // onExisting={onExistHandler}
                                                        name="tags"
                                                        isInvalid={
                                                            !!errors.tags && !!touched.tags}
                                                    />
                                                    
                                                    
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.tags}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="validationFormikisExclusive">
                                                <Form.Check
                                                    required
                                                    name="is_exclusive"
                                                    label="Is Exclusive"
                                                    onChange={handleChange}
                                                    // isInvalid={!!errors.isExclusive}
                                                    // feedback={errors.isExclusive}
                                                    // feedbackType="invalid"
                                                    // id="validationFormik0"
                                                />
                                            </Form.Group>
                                            {/* <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                {errors.isExclusive}
                                            </Form.Control.Feedback> */}
                                        </Col>

                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="validationFormikDescription">
                                                <InputGroup hasValidation className="d-flex flex-column">
                                                    <Form.Label className="fw-bold" >Description:</Form.Label>
                                                    <Form.Control aria-label="Default select example" isInvalid={
                                                        !!errors.description && !!touched.description}
                                                        type="text"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="description"
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        className="w-100"
                                                        as="textarea" rows={3} >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.description}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>

                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="validationFormikImage">
                                                <InputGroup hasValidation className="d-flex flex-column">
                                                    <Form.Label className="fw-bold" >Image:</Form.Label>
                                                    <Form.Control aria-label="Default select example" isInvalid={
                                                        !!errors.image && !!touched.image}
                                                        aria-describedby="inputGroupPrepend"
                                                        name="image"
                                                        value={values.image}
                                                        onInput={handleChange}
                                                        onChange={onImageChange}
                                                        className="w-100"
                                                        type="file"
                                                        accept="image/*"
                                                        ref={proImageRef}>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.image}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {
                                        (preImage.length>0)?
                                        <div className="row" id="preview_div">
                                            <div className="col-xs-12 col-md-12 mb-3">
                                                <div className="form-group">
                                                    <label className="fw-bold mb-2">Preview:</label>
                                                    <img src={preImage} id="preview" width="100%" alt="preview" />
                                                </div>
                                            </div>
                                        </div>
                                        :null
                                    }
                                    

                                    <Row>
                                        <Col md={12}>
                                            {
                                                (loader)?<LinearProgress />:null
                                            }
                                            
                                        </Col>
                                        <br/>
                                        <Col className="text-center">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className="add-form-btn add-image-btn"
                                            >
                                                Upload 
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>


            </div>
        </>
    )
}

export default AddImage