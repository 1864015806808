import React,{useState} from "react";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import useStyles from "./../authStyles";
import { CgMail } from "react-icons/cg";
import { RiLock2Line } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
// import { BsPerson } from "react-icons/bs";
// import BrandTitle from "../BrandTitle";
import * as yup from "yup";
import InputGroup from "react-bootstrap/InputGroup";
import { Formik } from "formik";
import axios from "axios"
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";


const schema = yup.object().shape({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    password: yup.string().required('Password is required')
});

const Login = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    const location = useLocation()
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };


    return (
        <>
            <Container className={classes.login_container}>
                <Row className="justify-content-center">
                    <Col lg={5} md={7} className="py-5">
                    <ToastContainer className={classes.toast_container}/> 

                        {/* <BrandTitle /> */}
                        <Card className={classes.login_card}>
                            <h3 className={classes.login_card_header}>Login</h3>
                            <Formik
                                validationSchema={schema}
                                onSubmit={(values, { setSubmitting, resetForm    }) => {
                                    setTimeout(() => {
                                        setSubmitting(false);
                                        // resetForm();
                                        axios.get('/sanctum/csrf-cookie').then(response => {
                                            axios.post("/api/login", values)
                                                .then(res => {
                                                    const persons = res.data;
                                                    console.log(persons)
    
                                                    if(res.data.status === 200){
                                                            // window.location.reload()
                                                            localStorage.setItem('auth_token', res.data.token);
                                                            localStorage.setItem('auth_data', JSON.stringify(res.data.userdata))
                                                            if(location.state?.from){
                                                                navigate(location.state.from);
                                                            }else{
                                                                navigate("/", { replace: true });
                                                            }
                                                            console.log(res.data.message)
    
                                                    }else if(res.data.status === 401){
                                                        toast(res.data.message)
                                                    }else{
                                                        let res_error = res?.data?.validation_errors;
                                                        toast(res_error.email[0])
                                                    }                                                 
                                                })                                                
                                            })
                                        })
                                    }
                                }
                                initialValues={{
                                    email: "",
                                    password: "",
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row >
                                        <div>
                                            <p>Login through following social sites</p>
                                            <button className="btn mb-3 social-btn google"><FcGoogle className="social-btn-icon" />Continue with Google</button>
                                            <button className="btn social-btn facebook"><FaFacebook className="social-btn-icon" />Continue with Facebook</button>
                                            <div className="d-flex my-3">
                                            <p className="line-border"></p>
                                            <p className="btn-seprator">Or</p>
                                            <p className="line-border"></p>
                                            </div>
                                        </div>
                                            <Form.Group className="mb-3" controlId="validationFormikEmail">
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">
                                                        <CgMail />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Email"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.email  &&  !!touched.email
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="validationFormikPassword">
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">
                                                        <RiLock2Line />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                       type={passwordShown ? "text" : "password"}
                                                        placeholder="Password"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.password &&  !!touched.password
                                                        }
                                                    />
                                                      <InputGroup.Text id="inputGroupPrepend">
                                                        <button className={classes.toggle_password} type="button" onClick={togglePassword}>{passwordShown ? <AiOutlineEye/>:<AiOutlineEyeInvisible/>}</button>
                                                    </InputGroup.Text>
                                                    <Form.Control.Feedback type="invalid" className={classes.login_feedback_errors}>
                                                        {errors.password}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-1 d-flex align-items-center  justify-content-between"
                                                controlId="formBasicCheckbox"
                                            >
                                                <label
                                                    className={
                                                        classes.login_form_checkbox
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="me-2"
                                                    />
                                                   Remember Me
                                                </label>
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    className={classes.auth_submit_btn}
                                                >
                                                    Login
                                                </Button>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                            <div className={classes.login_items_div}>
                                <Link to="/forgot-password" className={classes.login_items}>I forgot my password</Link>
                                <Link to="/signup" className={classes.login_items}>Register for a new membership</Link>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Login;
