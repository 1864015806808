import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import '../App.css'

const NotFound = () => {
  return (
    <Container>
        <Row>
            <Col>
                <div className="notfound">
                    <h1>404</h1>
                    <p>Page Not Found</p>
                    <Link to="/">
                        <Button className="notfound-btn">Back To Home Page</Button>
                    </Link>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default NotFound