import { makeStyles} from '@material-ui/core'

const useStyles = makeStyles( () => ({
    banner_area: {
        position: "relative",
    },
    banner_area_svg: {
        position: "absolute",
    },
    image_gallery :{
        cursor: "pointer",
        objectFit: "cover",
        '@media (max-width: 500px)': {
            width: "100%"
          },
    },
    // home_container:{
    //     padding: "100px 0"
    // },
    home_container_img:{
        height: 200,
        width: "100%",
        objectFit: "contain"
    },
    home_imgCaption_div:{
        backgroundColor: "black",
        color: "white"
    },
    home_imgCaption_div_cart:{
        color: "#6b6bff"
    },
    home_img_modal:{
        maxWidth: "100vw!important",
        // maxHeight:"100!important",
    },
    home_img_modal_body:{
        display: "flex",
        alignItems: "center"
    },
    image_galleryBox:{
        position: 'relative',
    },
    image_gallery_overlay:{
        margin: 0,
        // display:"none",
        // '&:hover':{
        //     display: "block",
        //     color: "white",
        //     position: "absolute",
        //     bottom: "0",
        //     background: "#00000057",
        //     margin: "0",
        //     width: "100%", 
        // },
    },
   
}));

export default useStyles