import React,{useState, useEffect, useRef, useCallback} from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap'
import { useNavigate} from "react-router-dom"
import ProfilePic from '../../../img/img1.jpg'
import "../userstyles.css"
import {FaUserEdit, FaFacebookSquare} from "react-icons/fa"
import {CgWebsite} from "react-icons/cg"
import {BsInstagram} from "react-icons/bs"
import axios from 'axios'
import HomeGallery from "../../imageGallery/HomeGallery"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
// import Skeleton from '@mui/material/Skeleton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useStyles from "../UserStyles";
import DefaultPhoto from "./../../../img/img1.jpg"
import Spinner from 'react-bootstrap/Spinner';
import "../userstyles.css"

const UserProfile = () => {
    const [items, setItems] =useState({})
    const navigate = useNavigate()
    const [image64,setImage64] = useState('')
    const [cropped64,setCropped64] = useState(ProfilePic)
    // const [path, setPath] = useState('')
    const [profilePhoto, setProfilePhoto] = useState('')
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true)


    const [isDesktop, setDesktop] = useState(window.innerWidth > 500);

    const updateMedia = () => {
      setDesktop(window.innerWidth > 500);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    let {id} = useParams();
    console.log(cropped64)
    const handleClick = ()=>{
        navigate("/user/edit-profile")
    }
    const ref= useRef()
  
    useEffect(() => {
        var user_id = id;
        if(user_id === undefined){
            const user = JSON.parse(localStorage.getItem('auth_data'))
            user_id = user.id
            console.log(user, user_id ,"hello")
        }
        axios.get("/api/user-data/" + user_id)
            .then((res) => {
                const resdata = res.data.data
                // setPath(res.data.path)
                setItems(resdata);
                if(resdata.avatar !== null){
                    setProfilePhoto(res.data.path + "/storage/profile/" + resdata.avatar)
                }
                else{
                    setProfilePhoto(null)
                }
                setLoading(false)
                console.log(resdata)
            })
            .catch((err) => console.log(err));
    }, [id]);

    const linkClick =()=>{
        window.open(items.website)
    }


  const handleClose = () => setShow(false);
  const handleShow = async() => {
    await ref?.current?.click()
  }


  const handleChange = useCallback((e)=>{
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
        setImage64(reader.result);
        setShow(true);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }, [])

//   console.log(image64)

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    console.log(croppedArea, croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    console.log(croppedAreaPixels,'test')
    try {
      const croppedImage = await getCroppedImg(
        image64,
        croppedAreaPixels,
      )

      console.log('donee', { croppedImage })

      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/update-avatar',{image:image64})
        .then((res)=>{
            toast(res.data.message)
            setShow(false)

            setCropped64(image64)
            setProfilePhoto(image64)
        }).catch((err)=>{
            console.log(err)
        })
    })
    //   setShow(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, setCropped64])
  
  const classes = useStyles();
  console.log(items?.website === null ? "hello" : "nohello")

  return (
    loading ? 
    <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
        <Spinner animation="border" size="sm"/> 
    </Container> : 
    <div className="userprofile-cont container-lg">
        <Row>
            <Col lg={3}>
                <ToastContainer className={classes.toast_container}/> 
                <div className='userprofile-details'>
                    <div className="userprofile-details-image">
                        {/* {
                            profilePhoto ? ( */}
                                <span onClick={handleShow} >
                                    {id === undefined ?  
                                    <>
                                        <input ref={ref} onChange={handleChange} type="file" hidden/>
                                        <div className='userprofile-userimage-icon' title="Change Profile Photo">
                                            <FaUserEdit  />
                                        </div> 
                                    </>
                                    : null}
                                    {
                                        profilePhoto !== null ?
                                        <img className="userprofile-userimage" src={profilePhoto} alt=""/> :
                                        <img className="userprofile-userimage" src={DefaultPhoto} alt=""/> 
                                    }
                                    
                                </span>
                            {/* ) : 
                            <Skeleton variant="circular" className='userprofile-userimage' width={200} height={200} />
                        } */}
                            <Modal show={show} onHide={handleClose} className="userprofile-modal" centered>
                                <Modal.Header closeButton>
                                <p className="m-0 crop-image-header">Crop Image</p>
                                </Modal.Header>
                                <Modal.Body style={{ height: '250px' }}>
                                    <Cropper
                                        className
                                        image= {image64}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1 / 1}
                                        cropSize={{width:225,height:225}}
                                        cropShape="round"
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                     {/* <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e, zoom) => setZoom(zoom)}
                                    /> */}
                                </Modal.Body>
                                <Modal.Footer className="d-flex justify-content-around">
                                    <Button variant="danger" onClick={handleClose} className="update-modal-closebtn">
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={showCroppedImage} className="update-modal-savebtn">
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        {/* edit button remaining */}
                    </div>
                    <div className='userprofile-userinfo'>
                       {id === undefined?<span className="userprofile-editbtn-div mb-3" onClick={handleClick}>
                            {/* <FaUserEdit  className='userprofile-edit-details'/> */}
                            <p className='m-0 userprofile-editbtn'>Edit Profile</p>
                        </span>:null}
                        <p className='userprofile-username'>{items.name}</p>
                       
                        <span className='d-flex justify-content-center '>
                            {items.website !== null?<CgWebsite className='userprofile-socialicons mx-3' title="Website URL" onClick={linkClick}/>:null}
                            {items.facebook !== null ?<FaFacebookSquare className='userprofile-socialicons mx-3' title="Facebook URL" onClick={()=>window.open(items.facebook)}/>:null}
                            {items.instagram !== null?<BsInstagram className='userprofile-socialicons mx-3' title="Instagram URL" onClick={()=>window.open(items.instagram)}/>:null}
                        </span>
                        {id === undefined?<span className="userprofile-editbtn-div-resp" onClick={handleClick}>
                            <FaUserEdit  className='userprofile-edit-details'/>
                            <p className='m-0 userprofile-editbtn'>Edit Profile</p>
                        </span>:null}
                    </div>
                </div>
            </Col>
            <Col lg={9}>
                <Tabs
                    defaultActiveKey="photos"
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                    >
                    <Tab eventKey="photos" title="Photos">
                    {isDesktop ? (
                        <HomeGallery datatype="profile" width={250}/>
                    ) : (
                        <HomeGallery datatype="profile" width="100%"/>
                    )}
                    </Tab>
                    <Tab eventKey="favorties" title="Favorites">
                        <p>Favorites</p>
                    </Tab>
                    {/* <Tab eventKey="gallery" title="Gallery">
                        <p>Gallery</p>
                    </Tab> */}
                    {/* <Tab eventKey="subscription" title="Subscription">
                        <p>Subscription</p>
                    </Tab> */}
                </Tabs>
            </Col>
        </Row>
    </div>
  )
}

export default UserProfile