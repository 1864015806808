import React,{useContext} from "react";
import { Row, Col, Badge } from "react-bootstrap";
import useStyles from "./detailStyles";
import { PhotoData } from "./Detail";

const DetailTags = () => {
    const classes = useStyles();
    const photo = useContext(PhotoData)

    // console.log(photo.tags)

    let tags = [photo.tags]
    let arrayTags =JSON.parse(tags)  

    return (
        <Row>
            <Col md={3} className={classes.detail_tags}> 
                <p className={classes.detail_tags_p}>Tags:</p>
                {
                    arrayTags.map(function(tag, index){
                            return (
                            <Badge key={index} pill className={classes.detail_tags_badge} bg="primary">{tag.value}</Badge>
                            ) 
                    })
                }
            </Col>
        </Row>
    );
};

export default DetailTags;
