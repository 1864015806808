import React from 'react'
import { BiRupee } from 'react-icons/bi'
import './compitition.css'

const Prizes = () => {
  return (
    <div className="prizes">
        <span className="prizes-card">
            <p className="prizes-card-ranking">1<span>st Price</span></p>
            <p className="prizes-card-reward">
                <BiRupee className='rupee-icon'/>10000
            </p>
            <p className="prizes-card-para">
            The competition winners who won first place in their nomination will obtain a cash prize of ten thousand rupees (INR 10,000).
            </p>
        </span>
        <span className="prizes-card">
            <p className="prizes-card-ranking">2<span>nd Price</span></p>

            <p className="prizes-card-reward">
                <BiRupee className='rupee-icon'/>5000
            </p>
            <p className="prizes-card-para">
            The competition winners who won second place in their nomination will obtain a cash prize of five thousand rupees (INR 5,000).
            </p>
        </span>
        <span className="prizes-card">
            <p className="prizes-card-ranking">3<span>rd Price</span></p>
            <p className="prizes-card-reward">
                <BiRupee className='rupee-icon'/>3000
            </p>
            <p className="prizes-card-para">
            The competition winners who won third place in their nomination will obtain a cash prize of three thousand rupees (INR 3,000).
            </p>
        </span>
    </div>
  )
}

export default Prizes