import React from 'react'
import { Container } from 'react-bootstrap';
import BannerBackground from "../../img/image27.jpg";
import "../home/home.css"

const Banner = ({enrollNow}) => {
  return (
    <Container fluid className="p-0" >
      <div className='banner'>
        <img className="banner-logo-img" src={BannerBackground} alt="" />
        <div className="banner-svg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff8f8" fillOpacity="1" d="M0,160L80,149.3C160,139,320,117,480,144C640,171,800,245,960,256C1120,267,1280,213,1360,186.7L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
          </svg>
        </div>
        <div className="banner-logo-img-div">
        </div>
        <div className="banner-content">
          <h1 className='banner-content-title mb-2'>Moment to appreciate the beauty of lens.</h1>
          <div className='banner-subcontent'>
            <p className="banner-content-para mb-3">#PixooterOfTheYear</p>
            <a href="#registration">
                <button className="banner-signup-btn" onClick={enrollNow}>Enroll Now</button>
            </a>  
          </div>
        </div>
        <div className='banner-subcontent-resp'>
            <p className="banner-content-para mb-3">Pixooter of the 2023</p>
            <a href="#registration">
                <button className="banner-signup-btn" onClick={enrollNow}>Enroll Now</button>
            </a>
        </div>
      </div>
    </Container>
  )
}

export default Banner