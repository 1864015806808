import React from "react";
import {Row, Col} from 'react-bootstrap'
import CartList from "./CartList";
import '../userstyles.css'

const Cart = () => {


    return (
        <div className="container-lg">
            <Row>
                {/* <Col lg={3}>
                    <div className={classes.user_sidebar}>
                        <SideBar />
                    </div>
                </Col> */}
                <Col lg={12}  className="my-3">
                    {/* <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className='m-0'>Cart</h4>
                       <Button className="btn btn-primary checkout-btn" onClick={onCheckoutHandler}>Checkout</Button> 
                    </div>
                    <hr /> */}
                    <div className="cart-table">
                        <CartList/>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Cart;
