import React from 'react'
import { ImCancelCircle } from 'react-icons/im'
import { Link } from 'react-router-dom'
import "./payment.css"
const PaymentFailed = () => {
  return (
    <div className="payment-success">
      <div className="payment-success-card">
        <ImCancelCircle className="success-logo"/>
        <p>Payment Failed!!</p>
        <span>Sorry your payment was not successful.</span>
        <Link to="/" className="btn btn-primary payment-btn">Back to Home</Link>
      </div>
    </div>
  )
}

export default PaymentFailed