import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import useStyles from "./FooterStyles";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../img/pixooterlogo.png"
import "./footer.css"

const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.footer_area}>
            <Container>
                <Row className={classes.footer_row}>
                    <Col lg={6}  className={classes.footer_logo}>
                        <Link to="/" className={classes.footer_logo_link}>
                            <img className={classes.footer_logo_img} src={Logo} alt="" />
                            {/* <h1 className="text-white text-start">
                                Pixooter
                            </h1> */}
                        </Link>
                        <div className="mb-4">
                            <p className="text-start footer-para mt-3">
                                Pixooter supports every genuine photography, mixed with different genres to get your idea in our platform.
                            </p>
                            <div className="my-3 d-flex">
                                <span className={classes.footer_social_iconfb} onClick={()=>window.open("https://www.facebook.com/SandeepRatanPhotography/")}>
                                    <FaFacebookF className={classes.footer_social_icon1}/>
                                </span>
                                <span className={classes.footer_social_iconin} onClick={()=>window.open("https://www.instagram.com/pixooter/")}>
                                    <BsInstagram className={classes.footer_social_icon2}/>
                                </span>
                            </div>
                        </div>
                    </Col>
                    <div className="col-lg-6 p-0 d-flex footer-components">
                        <Col lg={6} md={4} sm={6} >
                            <div className={classes.footer_li}>
                                <p className={classes.footer_li_header}>Company</p>
                                <NavLink to="/aboutus" className="footer_li_content">About us</NavLink>
                                <NavLink to="/terms" className="footer_li_content">Terms</NavLink>
                                <NavLink to="/privacy" className="footer_li_content">Privacy</NavLink>
                                <NavLink to="/licensing" className="footer_li_content">Licensing</NavLink>
                            </div>
                        </Col>
                        <Col lg={6} md={4} sm={6}>
                            <div className={classes.footer_li}>
                                <p className={classes.footer_li_header}>Need Help</p>
                                <NavLink to="/contactus" className="footer_li_content">Contact us</NavLink>
                                <NavLink to="/faq" className="footer_li_content">FAQ</NavLink>
                                <NavLink to="/pricing" className="footer_li_content">Pricing</NavLink>
                                <NavLink to="/submission-requirements" className="footer_li_content">Submission Requirements</NavLink>
                            </div>
                        </Col>
                    </div>
                    <Col md={12} className="footer-copyright">
                        <p className="text-center fw-bold m-0">
                            Copyright &copy; 2022. All rights reserved
                        </p>
                    </Col>
                    {/* <Col lg={12}>
                        <div className="d-flex justify-content-between mt-4">
                            <p className="text-white m-0">
                                Copyright &copy; 2022. All rights reserved
                            </p>
                            <div>
                                <Link className={classes.footer_social_icon}
                                    to="https://www.facebook.com/SandeepRatanPhotography/"
                                >
                                    <FaFacebookF
                                        className={classes.footer_social_icon1}
                                    />
                                </Link>
                                <Link className={classes.footer_social_icon}
                                    to="https://www.instagram.com/pixooter/"
                                >
                                    <BsInstagram
                                        className={classes.footer_social_icon2}
                                    />
                                </Link>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </Container>

            {/* <Container>
                <Row className={classes.footer_row}>
                    <Col md={8}>
                        <div className={classes.footer_bottom}>
                            <p className="col-lg-8 col-sm-12 text-white m-0 ">
                                Copyright &copy; 2022. All rights reserved
                            </p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={classes.footer_social}>
                            <Link className={classes.footer_social_icon}
                                to="https://www.facebook.com/SandeepRatanPhotography/"
                            >
                                <FaFacebookF
                                    className={classes.footer_social_icon1}
                                />
                            </Link>
                            <Link className={classes.footer_social_icon}
                                to="https://www.instagram.com/pixooter/"
                            >
                                <BsInstagram
                                    className={classes.footer_social_icon2}
                                />
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container> */}
        </footer>
    );
};

export default Footer;
