import {makeStyles} from '@material-ui/core'
import instaBg from "../../img/insta-bg.jpg"


const useStyles = makeStyles( () => ({
    footer_area:{
        backgroundColor:"#fff",
        position: "absolute",
        bottom: 0,
        width: "100%",
        textAlign: "center",
        zIndex: 1000,
        boxShadow: "0 0 15px rgb(0 0 0 / 10%)",
        ['@media (max-width:992px)']: { // eslint-disable-line no-useless-computed-key
            padding: 20
          }
    },
    footer_row:{
        padding: "25px 0",
        justifyContent: "space-between",
        alignItems: "center",
        ['@media (max-width:992px)']: { // eslint-disable-line no-useless-computed-key
            padding: 0
          }
    },
    footer_social:{
        display: "inline-flex",
    },
    footer_social_iconfb:{
        padding: "7px 12px",
       color: "#9b9b9b",
       backgroundColor: "#fff",
       textDecoration: "none",
       border: "1px solid #9b9b9b",
       marginRight: "10px !important",
       cursor: "pointer",
       '&:hover': {
        background: "#0079f2",
        // backgroundImage: "blue",
        color: "#fff",
        border: "none"
     },
    },
    footer_social_iconin:{
        padding: "7px 12px",
       color: "#9b9b9b",
       backgroundColor: "#fff",
       textDecoration: "none",
       border: "1px solid #9b9b9b",
       marginRight: "10px !important",
       cursor: "pointer",
       '&:hover': {
        background: "white",
        backgroundImage: `url(${instaBg})`,
        backgroundPosition: 'center', 
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
        color: "#fff",
        border: "none"
     },
    },
    footer_logo:{
        height: 160,
        // position: "relative",
        ['@media (max-width:992px)']: { // eslint-disable-line no-useless-computed-key
            height: "auto",
            padding: 0
          }
    },
    footer_logo_link:{
        display: "flex"
    },
    footer_logo_img:{
        width: 100,
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            width: 130
          }
    },
    footer_li:{
        textAlign: "start",
        color: "#000 !important",
        height: 160
    },
    footer_li_header:{
        fontSize: 20,
        fontWeight: 600,
        fontFamily: "Arvo, serif",
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: 18,
            marginBottom: "0.5rem"
          }
        // padding: "10px 0",
    },
 
}));

export default useStyles