import { Container, Col, Row, Spinner } from 'react-bootstrap'
import React,{useState, useEffect} from 'react'
import useStyles from "../UserStyles";
import SideBar from '../SideBar';
// import BarChart from "./BarChart";
// import LineChart from "./LineChart";
// import PieChart from "./PieChart";
// import DoughnutChart from "./DoughnutChart";
import {RiHeartsLine} from 'react-icons/ri'
import {ImDownload} from 'react-icons/im'
import {BsCashCoin} from 'react-icons/bs'
import {BiRupee} from 'react-icons/bi'
import {TbCameraPlus} from 'react-icons/tb'
// import { UserData } from "./Data";
import './dashboard.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Home = () => {

  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('auth_data'))
  console.log(user.name)
  const navigate = useNavigate()

  const [totalUpload , setTotalUpload] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  // const [userData, setUserData] = useState({
  //   labels: UserData.map((data) => data.year),
  //   datasets: [
  //     {
  //       label: "Photo Uploaded",
  //       data: UserData.map((data) => data.userGain),
  //       backgroundColor: [
  //         "rgba(75,192,192,1)",
  //         "#ecf0f1",
  //         "#50AF95",
  //         "#f3ba2f",
  //         "#2a71d0",
  //       ],
  //       borderColor: "black",
  //       borderWidth: 2,
  //     },
  //   ],
  // });

  const uploadImage = () => {
    navigate('/user/image-management')
  }

  const getImageData =()=> {

    axios.get('/api/get-user-images')
    .then((res) => {
      console.log(res.data.data.total)
      const total = res?.data?.data?.total
      setTotalUpload(total)
      setLoading(false)
    })
  }

  useEffect(() => {
      getImageData();
  }, []);

  const updateMedia = () => {
      setDesktop(window.innerWidth > 768);
  };
  
  useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="container-xl">
      <Row>
          {
            (isDesktop) 
            ?
                <Col lg={3} className="mt-3">
                    <div className={classes.user_sidebar}>
                        <SideBar />
                    </div>
                </Col>
            :
                <Col lg={3} className="sidebar-resp">
                    <div className={classes.user_sidebar}>
                        <SideBar />
                    </div>
                </Col>
          }
        <Col lg={9} className="my-4 dashboard-page content-resp">
          <h2 className="mb-3">Hi {user.name}!</h2>
          {
            (loading) ?  
            <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
              <Spinner animation="border" size="sm"/> 
            </Container>
            : 
            <div className="row">
              <div className="col-sm-3 home-cards">
                <div className="card-item uploaded-card" onClick={uploadImage}>
                  <span className="card-item-details">
                    <p className="card-item-total">{totalUpload}</p>
                    <p className="card-item-label">Photo Uploaded</p>
                  </span>
                  <span className="card-item-icon">
                    <TbCameraPlus className='card-item-logo'/>
                  </span>
                </div>
              </div>
              <div className="col-sm-3 home-cards">
                <div className="card-item downloads-card">
                  <span className="card-item-details ">
                    <p className="card-item-total">12</p>
                    <p className="card-item-label">Total Downloads</p>
                  </span>
                  <span className="card-item-icon">
                    <ImDownload className='card-item-logo'/>
                  </span>
                </div>
              </div>
              <div className="col-sm-3 home-cards">
                <div className="card-item earnings-card">
                  <span className="card-item-details ">
                    <p className="card-item-total">
                      <BiRupee className='rupee-icon'/>
                      2490</p>
                    <p className="card-item-label">Earnings</p>
                  </span>
                  <span className="card-item-icon">
                    <BsCashCoin className='card-item-logo'/>
                  </span>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card-item earnings-card">
                  <span className="card-item-details ">
                    <p className="card-item-total">472</p>
                    <p className="card-item-label">Total Likes</p>
                  </span>
                  <span className="card-item-icon">
                    <RiHeartsLine className='card-item-logo'/>
                  </span>
                </div>
              </div>
              {/* <div className="col-md-6">
                <BarChart chartData={userData} />
              </div> */}
              {/* <div className="col-md-6">
                <LineChart chartData={userData} />
              </div>
              <div className="col-md-6">
                <DoughnutChart chartData={userData} />
              </div>
              <div className="col-md-6">
                <PieChart chartData={userData} />
              </div> */}
            </div> 

          }
         
        </Col>
      </Row>
    </div>
  )
}

export default Home