import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import useStyles from "../company/CompanyStyles";
import {GiSergeant} from "react-icons/gi"
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import '../footer.css'


const Faq = () => {

  const classes = useStyles();
  const [faqs, setfaqs] = useState([
    {
      question: "What is Pixooter ?",
      answer: "Pixooter is founded on the principle of helping people discover new photos and photographers. We know that you care about how your personal information is used and shared, and we take your privacy very seriously. By visiting the Pixooter website, registering with Pixooter, or using any Pixooter services, you are accepting and agree to comply with this policy. The column on the right provides a short explanation of the privacy policy and is not legally binding.",
      open: false
    },
    {
      question: "Why can't I find the images I'm searching for?",
      answer: "Read our Search Tips for information on how to quickly find the images you need. We can also help you find the perfect and most relevant images you need. We have a professionally trained staff of researchers who know the collections like the back of their hands. You will receive an email with a direct link to the Lightbox we created.You need to Login or Register to avail this Free Research",
      open: false
    },
    {
      question:
        "Do I need to register to purchase?",
      answer: "Yes, you need to register. Click here for detailed registration information. If you are not yet registered, you will be prompted to complete your mailing and billing profile as part of the image purchasing process.",
      open: false
    },
    {
      question:
        "How can I get rights to the images I purchase?",
      answer: "All images on our site are available for the following purchases : Non-Exclusive Price, which is determined by 'file size' for buying an image under MASH Non-Exclusive Rights Agreement. What are Non-Exclusive Rights? Our images can be sold multiple times to different clients. Non-Exclusive Rights means that other people can buy the image you have chosen. Note : Nevertheless, you can't redistribute or resell images after taking NON-EXCLUSIVE RIGHTS. Click Here to view the detailed explanation of Non-Exclusive Rights Agreement.",
      open: false
    },
  ]);

  const toggleFAQ = (index) => {
    //console.log("toggled - nr:" + index);
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };


  return (
    <>
      <Container className='my-4'>
          <Row>
              <Col md={12}>
                <h2 className='section-header'><GiSergeant className={classes.title_icon}/>Faq</h2>
                <div className={classes.aboutus_block}>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="faqs">
                        {faqs.map((faq, i) => {
                          //console.log("i: " + i);
                          return (
                            <div
                              className={"faq " + (faq.open ? "open" : "")}
                              key={i}
                              onClick={() => toggleFAQ(i)}
                            >
                              <div className="faq-question">
                                {faq.question}
                              </div>
                              <div className="faq-answer">{faq.answer}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
          </Row>
      </Container>
      <ScrollUpButton style={{background: "transparent"}}/>
    </>

  )
}

export default Faq