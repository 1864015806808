import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import useStyles from "./CompanyStyles";
import {GiSergeant} from "react-icons/gi"
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import '../footer.css'

const Terms = () => {

  const classes = useStyles();

  return (
    <>
      <Container className='my-4'>
        <Row>
          <Col md={12}>
            <h2 className='section-header'><GiSergeant className={classes.title_icon}/>Terms of Use</h2>
            <div className={classes.aboutus_block}>
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="dev-content">
                    <h5 className="title">General</h5>
                    <div className={classes.aboutus_mint}></div>
                    <p className={classes.sub_para}>
                      Your use of this Web site (www.pixooter.com) means you agree to the following terms of use (the "Terms of Use"). If you do not agree with these terms you should leave this Web site (the "Site") immediately, not use the files herein, and/or not subscribe to this service.</p>
                    <p className={classes.sub_para}>
                      PLEASE READ THESE TERMS AND CONDITIONS OF USE (HERE IN AFTER REFERRED TO AS "TOU") CAREFULLY. THIS AGREEMENT GOVERNS YOUR USE OF WWW.PIXOOTER.COM (HERE IN AFTER REFERRED TO AS "WEBSITE"). YOUR USE OF THIS WEB SITE (WWW.PIXOOTER.COM) MEANS YOU AGREE TO THE FOLLOWING TERMS OF CONDITIONS OF USE (THE "TERMS OF USE") DESCRIBED HERE IN AND BY ALL TERMS, POLICIES AND GUIDELINES INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OR ANY OF THESE TERMS AND CONDITIONS, DO NOT USE THIS WEBSITE, LEAVE THIS WEBSITE IMMEDIATELY, NOT USE THE FILES HERE IN, AND/OR NOT SUBSCRIBE TO THIS SERVICE.
                    </p>
                    <p className={classes.sub_para}>
                      By using this website, you represent and warrant that you are over the age of 18 and are lawfully able to accept these Website terms of conditions of use. If you are using the Website on behalf of any entity, you further represent and warrant that you are authorized to accept these Website terms and conditions on such entity's behalf, and that such entity agrees to indemnify Pixooter for violations of these Website terms and conditions.
                    </p>
                    <p className={classes.sub_para}>
                      Pixooter may revise and update these TOU at anytime and without notice. Your continued access or use of this website after any such changes are posted will constitute your acceptance of these changes and you would be bound by the amended TOU.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.aboutus_block}>
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="dev-content">
                    <h5 className="title">Registration - Membership</h5>
                    <div className={classes.aboutus_mint}></div>
                    <p className={classes.sub_para}>
                      As a condition to using the Services, you are required to open an account with Pixooter, select a password and username, and to provide registration information including a valid email address, which may be used to transfer royalty payments to you for licensing Visual Content. The registration information you provide must be accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your access to the Services, by terminating your account. Any personal information that you provide to Pixooter is governed by the Pixooter Privacy Policy.</p>
                    <p className={classes.sub_para}>
                      You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar, or obscene.
                    </p>
                    <p className={classes.sub_para}>
                      You are responsible for maintaining the confidentiality of your password and are solely responsible for all activities resulting from the use of your password and conducted through your Pixooter account.
                    </p>
                    <p className={classes.sub_para}>
                      Services are available to authorized representatives of legal entities and to individuals who are either at least 18 years old to register for Premium Accounts or license Visual Content through Pixooter, or at least 14 years old, and who are authorized to access the Site by a parent or legal guardian. If you have authorized a minor to use the Site, you are responsible for the online conduct of such minor, and the consequences of any misuse of the Site by the minor. Parents and legal guardians are warned that the Site does display Visual Content containing nudity and violence that may be offensive to some.
                    </p>
                    <p className={classes.sub_para}>The Services are for use by individuals who own Visual Content; and entities that represent owners of Visual Content including, but not limited to, galleries, agents, representatives, distributors, and other market intermediaries. We are currently not accepting video, illustrations, and graphic design content to upload on the Site. If you are the owner of the Visual Content, but not the creator, you are not allowed to upload content for the purposes of self-advertising.</p>
                  </div>
                </div>
              </div>
            </div>
            
          </Col>
        </Row>
      </Container>
      <ScrollUpButton style={{background: "transparent"}}/>
    </>

  )
}

export default Terms