import React,{useEffect,useState} from 'react'
import useStyles from "../UserStyles";
import {ImCheckmark,ImCross} from 'react-icons/im'
// import AddImage from './AddImage';
import axios from 'axios'
import Paginate from "../uploadImage/Paginate";
import { Col, Row, Button,Table, Container, Spinner } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';


const SoldList = () => {
    const classes = useStyles();

    const [sold,setSold] = useState([])
    const [path, setPath] = useState('') 
    const [resData,setResData] =useState({})
    const [loading, setLoading] =useState(true)
    

    const onClickHandle = (pagelink)=>{
        getImageData(pagelink)
    }

  const getImageData = (pagelink = '/api/sold') => {
    axios.get(pagelink).then((res)=>{
      if(res.status === 200){
        const resdata=res.data.data
        console.log(resdata?.data)
        setResData(resdata);
        setLoading(false)
        console.log(resdata?.data)
        setSold(resdata?.data);
        setPath(res?.data?.path)
      }
    })
  }

  useEffect(() => {
    getImageData();
  }, []);

  const onDownload = (image_id)=>{
    axios.get('/api/original-download-sold/'+image_id)
    .then((res)=>{
      console.log(res)
    })
  }
  return (
    (loading) ?
    <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
        <Spinner animation="border" size="sm" /> 
    </Container>: 
    <>
    <div className="table-scroller">
      <Table striped bordered hover>
          <thead>
              <tr>
                  <th className="text-center">Order ID</th>
                  <th className="text-center">Image</th>
                  <th className="text-center">Category</th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Uploader</th>
                  <th className="text-center">isExclusive</th>
                  <th className="text-center">Action</th>
              </tr>
          </thead>
          <tbody>
          {
            (sold?.length > 0) ? 
              sold?.map((image,ind)=>{
              return (
                  <tr key={ind} className="align-middle">
                  <td className="text-center">PX0000{image.order_id}</td>
                  <td className="text-center">  
                      <img
                          src={path +"/" +image.thumbnail.replace(/public/i,"storage")}
                          width="50"
                          height="50"
                          alt="img"
                      />
                  </td>
                  <td className="text-center">{image.category_name}</td>
                  <td className="text-center">{image.price}</td>
                  <td className="text-center">{image.name}</td>
                  <td className="text-center"> {image.is_exclusive === 0 ? <ImCross/> : <ImCheckmark/>}</td>
                  <td className="text-center"><Button className="btn btn-success sold-invoice" onClick={()=>onDownload(image.id)} title="Download Invoice"><FiDownload className={classes.imagelist_icons}/></Button></td>
                  </tr>
              )
              })
              : 
              <tr><td className="text-center" colSpan={7}>No products</td></tr>
          }
          </tbody>
      </Table>
    </div>
    <Row>
        <Col md={12}>
            <Paginate resdata={resData} onClickHandle={onClickHandle}/>
        </Col>
    </Row>
    </>

  )
}

export default SoldList