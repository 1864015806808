import React,{useState,useEffect}  from 'react'
import { Container, Spinner, Card, Button} from 'react-bootstrap'
import useStyles from "../UserStyles";
// import {ImCheckmark,ImCross} from 'react-icons/im'
import axios from 'axios'
// import {RiDeleteBin6Line} from 'react-icons/ri'
import {RiDeleteBin5Line} from 'react-icons/ri'
import {TbShoppingCartX} from 'react-icons/tb'
import "../userstyles.css"
import { ToastContainer, toast } from 'react-toastify';
// import { BiWindowOpen } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { MdImageSearch } from 'react-icons/md';

const CartList = () => {
    const classes = useStyles();
    // const [serialno,setSerialNo] = useState(1);
    const [cartData,setCartData] = useState([])
    const [path,setPath] = useState('')
    const [loading, setLoading] = useState(true)
    const [totalItems, setTotalItems] = useState()
   
    const initialValue = 0;
    const total = cartData.reduce((accumulator,current) => accumulator + current.price , initialValue) 

    const getCartData =()=>{
        axios.get('/api/get-cart-data').then((res)=>{
            setCartData(res?.data?.data)
            setPath(res?.data?.path)
            setLoading(false)
            setTotalItems(res?.data?.data?.length)
        })
    }
    useEffect(() => {
        getCartData();
    }, [])
    console.log(cartData)
    const deleteCartImage = (image_id)=>{
        axios.delete('/api/remove-product-from-cart/api/'+image_id)
        .then((res)=>{
            console.log(res);
            getCartData();
            toast(res.data.message)
        })
    }
    const onCheckoutHandler = ()=>{
        axios.post('/api/checkout')
        .then((res)=>{
            window.location.replace(res.data);
            setCartData("")
        })
    }

    // let srno = serialno;

  return (
    (loading) ?
    <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
        <Spinner animation="border" size="sm" /> 
    </Container>:
    <>
        <div className="cartlist-header my-3 mx-2">
            <h4 className=''>Order Summary ({totalItems} Items)</h4>
            {/* {
                (totalItems === 0) ? "" :
            <Button className="btn checkout-btn" onClick={onCheckoutHandler}>Complete Order: &#8377;{total}</Button>
            } */}
        </div>
        {/* <hr /> */}
        <div className="cartlist-div">
            <ToastContainer className={classes.toast_container}/> 
            {
                (cartData?.length > 0) ?
                cartData?.map((image, i) => {
                    return ( 
                        <div key={i}>
                            <Card className="cart-item-card">
                                <div className="cartlist-img-div">
                                    <span className="cartlist-img-title fw-bold">Item</span>
                                    <img
                                        src={path +"/" +image.thumbnail.replace(/public/i,"storage")}
                                        alt="img"
                                        className="cart-productImage"
                                    />
                                </div>
                                <div className="cartlist-details">

                                    <div className="cartlist-details-div">
                                        {/* <p className=""><span></span>{image.user_name}</p> */}
                                        <p className="mb-1 fw-bold cartlist-details-caption">
                                            {/* <span className="fw-bold">Caption:</span> */}
                                            {image.caption} by {image.user_name}
                                        </p>
                                        <p className="mb-2 cartlist-details-category"> 
                                            {/* <span className="">Category:{" "}</span> */}
                                            {image.category_name}
                                        </p>
                                        {/* <p className="">
                                            <span>Is Exclusive:</span> {image.is_exclusive === 0 ? <ImCross/> : <ImCheckmark/>}
                                        </p> */}
                                    </div>

                                    <div className="cartlist-price-div">
                                        
                                        <p className="mb-2 d-flex flex-column fw-bold">
                                            <span className="fw-bold mb-3">Price</span>
                                            &#8377;{image.price}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                            
                                        {/* <div className="remove-item-div">
                                        <button className="remove-cart-btn w-100" onClick={()=>deleteCartImage(image.id)}><RiDeleteBin6Line  className={classes.imagelist_icons}/>Remove Item</button>
                                        </div> */}
                                        <button className="remove-cart-btn" onClick={()=>deleteCartImage(image.id)} title="Remove Image"><RiDeleteBin5Line className="remove-cart-btn-icon"/></button>
                                        <button className="remove-cart-btn-resp" onClick={()=>deleteCartImage(image.id)} title="Remove Image"><RiDeleteBin5Line className="remove-cart-btn-icon"/></button>
                                    </div>
                                </div>
                            </Card>
                        </div>

                    )
            }) 
            :
                <div className="cartlist-noproducts">
                    <TbShoppingCartX className='cartlist-noproducts-icon'/>
                    <p className="cartlist-noproducts-desc">Your cart is empty!</p>
                    <Link to="/home">
                        <Button variant="secondary" className="explore-btn"><MdImageSearch className="me-1"/>Explore Images</Button>
                    </Link>
                </div>
            }
            <div className="cartlist-totalprice me-2">
                <p>Total:  &#8377;{total}</p>
            </div>
            {
                (totalItems === 0) ? "" :
                <div className="cartlist-complete-btn">
                    <Button className="btn checkout-btn" onClick={onCheckoutHandler}>Complete Order</Button>
                </div>
            }
        </div>
      
    </>
  )
}

export default CartList