import React,{useEffect,useState} from 'react'
import {ImCheckmark,ImCross} from 'react-icons/im'
import Paginate from "../uploadImage/Paginate";
// import AddImage from './AddImage';
import axios from 'axios'
import { Col, Row, Button,Table, Modal, InputGroup, Form, Spinner, Container } from 'react-bootstrap';
import {FiDownload} from 'react-icons/fi'
import useStyles from "../UserStyles";
import {saveAs} from 'file-saver'


const PurchasedList = () => {
    const classes = useStyles();
    const [purchased,setPurchased] = useState([])
    const [path, setPath] = useState('') 
    const [license,setLicense] = useState('')
    const [imageId , setImageId] = useState(0);
    const [resData,setResData] =useState({})
    const [loading, setLoading] =useState(true)
    const [show, setShow] = useState(false)


    const onClickHandle = (pagelink)=>{
        getImageData(pagelink)
    }

    const getImageData = (pagelink = '/api/purchased') => {
        axios.get(pagelink)
        .then((res)=>{
        if(res.status === 200){
            const resdata=res.data.data
            console.log(resdata?.data)
            setResData(resdata);
            setPurchased(resdata?.data);
            setPath(res?.data?.path)
            setLoading(false)

        }
        })
    }

    useEffect(() => {
        getImageData();
    }, []);

    const onChangeHandler = (e) =>{
            setLicense(e.target.value)
    }

    const onClickHandler = (image_id) =>{
        setShow(true)
        setImageId(image_id)
    }

    // function str2bytes (str) {
    //     var bytes = new Uint8Array(str.length);
    //     for (var i=0; i<str.length; i++) {
    //         bytes[i] = str.charCodeAt(i);
    //     }
    //     return bytes;
    // }

    const onDownload = () =>{
        axios.post('/api/original-download/'+imageId,
        {"project":license}
        // ,{
        //     headers: {
        //         'Content-type': 'application/zip'
        //     }
        // }
        )
        .then((res)=>{
            // var data = new Blob([res.data]);
            // var csvURL = window.URL.createObjectURL(data);
            // let tempLink = document.createElement('a');
            // tempLink.href = res.data;
            // tempLink.setAttribute('download','abc.zip');
            // tempLink.click();
            console.log(res)

            // var blob = new Blob([str2bytes(res.data)], {type: "application/zip"});
            // saveAs(blob, "data.zip");
            
            
            var blob = new Blob([res.data], {type: "application/zip"});
            saveAs(blob, "helloworld.zip");

        }) 
    }


    const handleClose = () => setShow(false);

    
  return (
    <>
    {       
        (!show)?null:
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Download Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup hasValidation>
                    <Form.Control type="text" onChange={onChangeHandler} required/>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onDownload}>
                    Download
                </Button>
            </Modal.Footer>
        </Modal>
        // <div>
        //     <input type="text" onChange={onChangeHandler} /><Button onClick={onDownload}>Download</Button>
        // </div>
    }
    {
        (loading) ?
        <Container className="d-flex align-item-center justify-content-center p-1 mt-3">
            <Spinner animation="border" size="sm" /> 
        </Container>:
        <>
            <div className="table-scroller">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th className='text-center'>Order ID</th>
                        <th className='text-center'>Image</th>
                        <th className='text-center'>Category</th>
                        <th className='text-center'>Price</th>
                        <th className='text-center'>Uploader</th>
                        <th className='text-center'>isExclusive</th>
                        <th className='text-center'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (purchased?.length > 0) ? 
                            purchased?.map((image,ind)=>{
                            return (
                            <tr key={ind} className="align-middle">
                                <td className="text-center">PX0000{image.order_id}</td>
                                <td className="text-center">  
                                <img
                                    src={path +"/" +image.thumbnail.replace(/public/i,"storage")}
                                    width="50"
                                    height="50"
                                    alt="img"
                                    />
                                </td>
                                <td className="text-center">{image.category_name}</td>
                                <td className="text-center">{image.price}</td>
                                <td className="text-center">{image.name}</td>
                                <td className="text-center"> {image.is_exclusive === 0 ? <ImCross/> : <ImCheckmark/>}</td>
                                <td className="text-center"><Button className="btn btn-success purchased-invoice" onClick={()=>onClickHandler(image.id)} title="Download Invoice"><FiDownload className={classes.imagelist_icons}/></Button></td>
                            </tr>
                            )
                        }) :
                        <tr><td className="text-center" colSpan={7}>No products</td></tr>
                        }
                    </tbody>
                </Table>
            </div>
            <Row>
                <Col md={12}>
                    <Paginate resdata={resData} onClickHandle={onClickHandle}/>
                </Col>
            </Row>
        </>
    }
    </>
  )
}

export default PurchasedList