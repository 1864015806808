import React from 'react'
import { useParams } from 'react-router-dom'
import HomeGallery from '../imageGallery/HomeGallery'


const Search = () => {

    const {key1} =useParams()
    console.log(key1, "key search.sjsc")

  return (
    <div>
        <HomeGallery key1={key1}/>
    </div>
  )
}

export default Search