import React from 'react'
import "./compitition.css"

const Rules = () => {
  return (
    <div id="rules">
        <div className="rules-col d-flex mb-4">
            <div className="col-md-3 ">
                <span className="rules-title">
                    <p className="">
                        WHO CAN ENTER?
                    </p>
                </span>
            </div>
            <div className="col-md-9 ">
                <span className="rules-data">
                    <p>
                    Entry is open to all photographers, artists, curators, archives, collectives from anywhere in the world. The Jury will be looking for high quality work.
                    </p>
                    <p>
                    Submissions must be photographic or lens based but can include incorporations of other art forms with the photographic medium (i.e. performance, painting, sculpture, film, music, literature etc)
                    </p>
                    <p>
                    The Jury will consider photographs, photo-media, performance, photo-films, photo-books, new media, archives and sculptural forms. All works must be practical within the Festival guidelines and dates. If you have the information, please include install images as part of your submission
                    </p>
                    <p>
                    Applicants must be Age 18+
                    </p>
                </span>
            </div>
        </div>
        <div className="rules-col d-flex mb-4">

            <div className="col-md-3 ">
                <span className="rules-title">
                    <p className="">
                        THEME
                    </p>
                </span>
            </div>
            <div className="col-md-9 ">
                <span className="rules-data">
                    <p>
                    The theme for the 2023 Open Submission has been left open, therefore removing any restrictions in regards to theme, concept or approach of a photographer's/artist's work.
                    </p>
                </span>
            </div>
        </div>
        <div className="rules-col d-flex mb-4">
            <div className="col-md-3 ">
                <span className="rules-title">
                    <p className="">
                        TEAMS AND CONDITION
                    </p>
                </span>
            </div>
            <div className="col-md-9 ">
                <span className="rules-data">
                    <p>
                    Only online submissions will be accepted.
                    </p>
                    <p>
                    Only minor burning, dodging and/or colour correction is acceptable, as is cropping.
                    </p>
                    <p>
                    All entries should be made using the entrant’s legal name. Any dishonest information provided by the entrant will be disqualified.
                    </p>
                    <p>
                    Once notified, the winner must reply within a 10 day period.
                    </p>
                    <p>
                    If the selected winner is unreachable, ineligible, disqualified or fails to claim the prize [within 10 days of notification], the winner automatically forfeits the prize.
                    </p>
                    <p>
                    No incomplete, illegible or corrupted entries will be accepted.
                    </p>
                    <p>
                    Failure to comply with any of these terms and conditions (including instructions) will disqualify any entrant from taking part in the Competition and the entry will be invalid.
                    </p>
                </span>
            </div>
        </div>
        {/* <div className="rules-col d-flex">
            <div className="col-md-3 ">
                <span className="rules-title">
                    <p className="mb-0">
                        WHO CAN ENTER?
                    </p>
                </span>
            </div>
            <div className="col-md-9 ">
                <span className="rules-data">
                    <p>
                    Entry is open to all photographers, artists, curators, archives, collectives from anywhere in the world. The Jury will be looking for high quality work.
                    </p>
                    <p>
                    Submissions must be photographic or lens based but can include incorporations of other art forms with the photographic medium (i.e. performance, painting, sculpture, film, music, literature etc)
                    </p>
                    <p>
                    The Jury will consider photographs, photo-media, performance, photo-films, photo-books, new media, archives and sculptural forms. All works must be practical within the Festival guidelines and dates. If you have the information, please include install images as part of your submission
                    </p>
                    <p>
                    Applicants must be Age 18+
                    </p>
                </span>
            </div>
        </div> */}
    </div>
  )
}

export default Rules