import React ,{useEffect}from 'react'
import config from './../../../config'
import useStyles from "./../authStyles";

// for admin login 
const Admin = () => {
  const classes = useStyles();
  
  useEffect(() => {
    window.location.href= config.BASE_URL+"/admin/users"
  }, [])
    
  return (
    <div className="container">
      <div className={classes.redirecting}>
        <p className={classes.redirecting_p}>Redirecting...</p>
      </div>
    </div>
  )
}

export default Admin