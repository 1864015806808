import React,{useRef, useState} from "react";
import useStyles from "../authentication/authStyles";
import { Formik } from 'formik';
import { Card, Form, Row, Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import * as yup from "yup";
// import {useLocation,useNavigate} from 'react-router-dom'

const Registration = () => {

    const classes = useStyles();
    // let navigate = useNavigate();
    // const location = useLocation();
    const [preImage,setPreImage] = useState('')
    const proImageRef = useRef();

    const onImageChange = (e) =>{
        // setMyImage(e.target.value)
        console.log(proImageRef.current.files[0])
        console.log(URL.createObjectURL(proImageRef.current.files[0]))
        setPreImage(URL.createObjectURL(proImageRef.current.files[0]))
        // console.log(URL.createObjectURL(proImageRef.current.files[0]))
    }
    console.log(preImage)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = yup.object().shape({
        name: yup.string().required('Name is required').min(2, 'Name must be above 2 characters'),
        email: yup.string().email('Email must be a valid email').required('Email is required'),
        phone: yup.string().required("Phone number is required").matches(phoneRegExp, 'Phone number is not valid').min(10, "Phone number is not valid").max(10, "Phone number is not valid"),
        image: yup.mixed().nullable().required('Image is required')
        .test("fileSize", "Too small!", (value) => {
            return(
                (value && proImageRef.current) ?
                ((proImageRef.current.files[0].size >= 60000)  ? true : false)   //60kb
                : true)
        })
        .test("fileSize", "Too big!", (value) => {
            return(
                value && proImageRef.current ?
                (proImageRef.current.files[0].size <= 6000000  ? true : false)   //6mb
                : true)
        }),
    });
    
  return (
    <div className="d-flex justify-content-center align-items-center" >
        <Card className="registration-card" id="registration">
            <h5 className={classes.login_card_header}>Fill the details below</h5>
            <Formik
                validationSchema={schema}
                initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    image: "",
                }}
                onSubmit={values => {
                    // same shape as initial values
                    console.log(values);
                }}
                >
                {({  
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,    
                    errors, 
                    }) => (
                    
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row >
                            <Form.Group className="mb-3" >
                                <InputGroup hasValidation>
                                     <Form.Control id="standard-basic" 
                                      label="Name" 
                                      variant="standard"
                                      type="text"
                                      aria-describedby="inputGroupPrepend"
                                      name="name"
                                      className="w-100"
                                      placeholder="Full name"
                                      value={values.name}
                                      onChange={handleChange}
                                      isInvalid={
                                          !!errors.name && !!touched.name
                                      } 
                                      />
                                    <Form.Control.Feedback type="invalid" className="regtn_feedback_errors">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="validationFormikEmail">
                                <InputGroup hasValidation>
                                     <Form.Control id="standard-basic" 
                                     label="Email" 
                                     variant="standard" 
                                     type="text"
                                     aria-describedby="inputGroupPrepend"
                                     name="email"
                                     className="w-100"
                                     placeholder="Email"

                                     value={values.email}
                                     onChange={handleChange}
                                     isInvalid={
                                         !!errors.email && !!touched.email
                                     }/>
                                    <Form.Control.Feedback type="invalid" className="regtn_feedback_errors">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="validationFormikPhone">
                                <InputGroup hasValidation>
                                     <Form.Control id="standard-basic" 
                                     label="Phone" 
                                     variant="standard" 
                                     type="number"
                                     aria-describedby="inputGroupPrepend"
                                     name="phone"
                                     className="w-100"
                                     placeholder="Phone"
                                     value={values.phone}
                                     onChange={handleChange}
                                     isInvalid={
                                         !!errors.phone && !!touched.phone
                                     }/>
                                    <Form.Control.Feedback type="invalid" className="regtn_feedback_errors">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="validationFormikEmail">
                                <InputGroup hasValidation>
                                    <Form.Control aria-label="Default select example" isInvalid={
                                        !!errors.image && !!touched.image}
                                        aria-describedby="inputGroupPrepend"
                                        name="image"
                                        value={values.image}
                                        onInput={handleChange}
                                        onChange={onImageChange}
                                        className="w-100"
                                        type="file"
                                        accept="image/*"
                                        ref={proImageRef}>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" className="regtn_feedback_errors">
                                        {errors.image}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group
                                className="mb-1 signup-terms-div justify-content-center"
                                controlId="formBasicCheckbox"
                            >
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className={classes.auth_submit_btn}
                                >
                                    Submit
                                </Button>
                                <Form.Control.Feedback type="invalid" className="regtn_feedback_errors">
                                        {errors.agree_terms}
                                </Form.Control.Feedback>
                                
                            </Form.Group>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Card>
        
    </div>
  )
}

export default Registration